require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/restoration/restoration.template.hbs');
var AfterTemplate = require('text-loader! ../../ui/restoration/view/after.restoration.template.hbs');
var BeforeTemplate = require('text-loader! ../../ui/restoration/view/before.restoration.template.hbs');
var SuccessTemplate = require('text-loader! ../../ui/restoration/view/success.restoration.template.hbs');
require("notify");

var TheView = GenericView.extend({
    el: $("#full-content"),
	template: Handlebars.compile(Template),
	afterRestorationTemplate: Handlebars.compile(AfterTemplate),
	beforeRestorationTemplate: Handlebars.compile(BeforeTemplate),
	successRestorationTemplate: Handlebars.compile(SuccessTemplate),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
        this.render();
        console.info(options);
	    if(options.token == null){
            $("#container").html(this.beforeRestorationTemplate())
            return;
        }
        this.token = options.token
        $("#container").html(this.afterRestorationTemplate())

	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		var self = this;

		this.$el.html(this.template());
	},
	events:{
	    "click #button.restore" : "beforeRestore",
	    "click #button.reset" : "resetPassword",
	    "keyup input#password": "isValidPassword"
	},
	isValidPassword: function(){
	    var input = $("#password").val().split("");
	    var numbers = 0;
	    var upperCharacters = 0;
	    var lowerCharacters = 0;
	    var specialCharacters = 0;

	    for(var i=0; i< input.length; i++){
	        var char = input[i];
	        if(0 <= char && char <= 9){
                numbers++;
	        }else if("A" <= char && char <= "Z"){
                upperCharacters++;
	        }else if("a" <= char && char <= "z"){
                 lowerCharacters++;
            }else if(char == "!" || char == "@" || char == "#" || char == "%" || char == "&"){
                specialCharacters++;
            }

	    }

	    console.log("numbers="+numbers+" upperCharacters="+ upperCharacters+" lowerCharacters="+lowerCharacters+" specialCharacters="+specialCharacters);

	    if(numbers >= 2 && upperCharacters >= 2 && lowerCharacters >= 2, specialCharacters >= 2){
	        $("#password-error").removeClass("show");
	        return true
	    }else{
	        $("#password-error").addClass("show");
	        return false;
	    }

	},
	beforeRestore: function(){
        var email = $("#email").val()

        if(email == ""){
            $.notify("Συμπληρώστε το νέο κωδικό!", { position:"top center", className: "error"});
            return;
        }

        $("#loader").show();
        $("#button").hide();

        $.ajaxDefaultQ({
            url: window.ip + '/auth/restore/send?email='+email,
            type: 'POST'
        }).done(function(response, status, xhr){
            console.info(response)
            $.notify("Παρακαλώ μπείτε στο email σας και πατήστε στο link που σας στείλαμε!", { position:"top center", className: "success"});
            $("#loader").hide();
        })
        .fail(function(response) {
            if(response.status == "406")
                $.notify("Δεν υπάρχει η ηλεκτρονική διεύθυνση!", { position:"top center", className: "error"});
            console.info(response);
            $("#loader").hide();
            $("#button").show();

        });
	},
	resetPassword: function(){
	    var self = this;
        var password = $("#password").val()
        var rePassword = $("#rePassword").val()



        if(password == ""){
            $.notify("Συμπληρώστε το νέο κωδικό!", { position:"top center", className: "error"});
            return;
        }

        if(password != rePassword){
            $.notify("Οι κωδικοί δεν ταιρίαζουν!", { position:"top center", className: "error"});
            return;
        }

        $("#loader").show();
        $("#button").hide();

        $.ajaxDefaultQ({
            url: window.ip + '/auth/restore?r=' + this.token + '&password='+ password,
            type: 'POST'
        }).done(function(response, status, xhr){
            console.info(response)
            $("#container").html(self.successRestorationTemplate())
        })
        .fail(function(response) {
            $("#loader").hide();
            $("#button").show();
            $.notify("Δεν μπορέσαμε να ολοκληρώσουμε την αλλαγή κωδικού! Παρακαλώ προσπαθείστε ξανα..", { position:"top center", className: "error"});
        });
	}
});


module.exports= TheView;