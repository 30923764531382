require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/clinic_admin/clinic_admin.template.hbs');
var Model = require('../../ui/clinic_admins/models/clinic_admin.model.ts');
require("loader");
require("notify");
var Validation = require("validation");

var TheView = GenericView.extend({
    el: "#content",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
	 console.info('options',options)
	    this.self = this
	    this.loadDetails(options);
	},
	isNew: function(){
	    return this.id == null || this.id == "New";
	},
    loadDetails: function(options){
        this.id = options.id;

        this.model = new Model();
        this.model.urlRoot = window.ip + "/api/admin/clinic-admins";

        this.render();

        if(this.isNew()){
            return;
        }

        this.model.set("id", this.id);
        this.fetch(this.model, this.onSuccess, this.onError);
    },
    onSuccess: function(response, xhr, status){
        //this.fetch(this.medicalModel, this.onMedicalSuccessFetch, this.onMedicalErrorFetch);
        this.render();
    },
    onError: function(response, xhr, status){
    },
    initializeValidation: function(){
        this.validation = new Validation();
        //Pattern firstName(is the id from input): [ {maxLength(validation): 10}]
        this.validation.addValidations(
        {
            firstName: [],
            lastName: [],
            email: [ {pattern: "email"} ]
        });

    },
    getTitle: function(){
        if(!this.isNew()){
            console.info('new', this.model);
            if(this.model.get("user") == null)
                return "Διαχειριστής Κλινικής: ";

            return "Διαχειριστής Κλινικής: " + this.model.get("user").lastName;
        }

        return "Δημιουργία Διαχειριστή Κλινικής";
    },
    isEditable: function(){
        return this.patientId != null
    },
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		this.$el.html(this.template({
		    clinicAdmin: this.model.toJSON(),
		    title: this.getTitle()
		}));

		if(this.validation == null)
		    this.initializeValidation();
	},
	events:{
        "click #save": "check",
        "click #cancel": "cancel",
        "click .examinations tr": "openExamination",
	},
	cancel: function(){
	    this.goBack();
	},
	check: function(){
	    console.info(this.validation.isAllFieldValidate());
        //if(this.validation.isAllFieldValidate()){
          this.save(this.buildModel(), this.onSuccessSave, this.onErrorSave);
        //}
	},
	onSuccessSave: function(data, statusText, xhr){
        $.notify("Τα δεδομένα αποθηκεύτηκαν με επιτυχία", { position:"top center", className: "success"});
	    if(this.isNew())
	        this.goBack();
	},
	onErrorSave: function(model, response){
        $.notify("Τα δεδομένα δεν αποθηκεύτηκαν", { position:"top center", className: "error"});
	},
	buildModel: function(){
	    var user = this.model.get("user");
	    console.info(this.model);

        user.firstName = $("#firstName").val().trim();
        user.lastName = $("#lastName").val().trim();
        user.email = $("#email").val().trim();
        user.firstName = $("#firstName").val().trim();

        var activated = $("#activated").val();
        if(activated == "0")
            user.activated = false;
        else
            user.activated = true;

        this.model.set("user", user);
	    return this.model;
	}
});

module.exports= TheView;
//address: (1) […]
//0: Object { street: "asdasd", number: "21", postalCode: "23", … }
//contactInfo: Object { phone: "", mobile: "", email: "d.amoutzidis@msensis.com" }
//dateOfBirth: 1586256336708
//educationLevel: "Other"
//educationLevelInfo: ""
//familialStatus: "Single"
//familialStatusInfo: ""
//fathersName: "Γεώργιος"
//firstName: "Δημήτριος"
//gender: "Male"
//hobbies: ""
//id: "5e8c59d0b8144d3701d43fdb"
//lastName: "Αμουτζίδης"
//nationality: "Ελληνική"
//occupation: ""
//referredFrom: ""
//technologyAptitudeLevel: "Medium"
//userDeviceInfo: ""