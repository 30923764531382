Backbone = require('backbone');
Handlebars = require('handlebars');
Template = require('text-loader! ../../templates/parts/pagination.htm');
var HandlebarsHelpers = require('../../../libs/handlebars/handlebars-helpers.ts');
require("loader");


var PaginatedView = Backbone.View.extend({
	events: {
		'click .pagination .first a': 'gotoFirst',
		'click .pagination .previous a': 'previousResultPage',
		'click .pagination .page a': 'gotoPage',
		'click .pagination .next a': 'nextResultPage',
		'click .pagination .last a': 'gotoLast',
		'click .pagination .serverhowmany a': 'changeCount',
		'change .pagination select#sortByField': 'updateSortBy'
	},
	tagName: 'aside',
	template: _.template(Template),
	initialize: function () {
		this.collection.on('reset', this.render, this);
		//this.collection.on('change', this.render, this);
	},

	render: function () {
		var html = this.template(this.collection.info());
		this.$el.html(html);
		this.delegateEvents(this.events);
	},
	// Events
	gotoFirst: function (e) {
		e.preventDefault();
		if(this.collection.state.firstPage != this.collection.state.currentPage){
			$.loader.show();
			this.collection.getFirstPage();

		}
	},

	previousResultPage: function (e) {
		e.preventDefault();
		if(this.collection.state.currentPage > this.collection.state.firstPage){
			$.loader.show();
			this.collection.getPreviousPage();
		}
	},

	gotoPage: function (e) {
		e.preventDefault();
		var page = parseInt($(e.target).text())-1;
        $.loader.show();
		this.collection.getPage(page);
	},

	nextResultPage: function (e) {
		e.preventDefault();
		if(this.collection.state.currentPage < this.collection.state.lastPage){
			$.loader.show();
			this.collection.getNextPage();
		}
	},

	gotoLast: function (e) {
		e.preventDefault();
		if(this.collection.state.lastPage != this.collection.state.currentPage){
			$.loader.show();
			this.collection.getLastPage();
		}
	},

	changeCount: function (e) {
		e.preventDefault();
		var per = parseInt($(e.target).text());
        $.loader.show();
        this.collection.setPageSize(per);
	},

	updateSortBy: function (e) {
		e.preventDefault();
		var currentSort = $('#sortByField').val();
		this.collection.setSorting(currentSort);
	}
	// End of Events

});

// Returning instantiated views can be quite useful for having "state"
module.exports= PaginatedView;