require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../templates/bars/header.template.hbs');
var UserProfileView = require('./tools/user_profile.view.ts')
//var NotificationTool = require('./tools/notification.view.ts')

var TheView = GenericView.extend({
    el: "#header",
    tagName: 'header',
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
        this.render();
        this.userProfile = new UserProfileView(options);
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		var self = this;
		this.$el.html(this.template());
		this.initializeTools();
	},
	events:{
	},
	createTools: function(toolsArray){
	    var toolView = $('#tools');
	    this.createdToolsArray = new Array();

	    for (i = 0; i < toolsArray.length; i++) {
            var li = $('<li id="tools-' + i + '"/>');
            toolView.append(li);
            this.createdToolsArray[i] = this.getToolByPrefix( toolsArray[i], { data: "tools-" + i } );
        }
	},
	initializeTools: function(){
        var navigationTools = "[data-toggle='offcanvas']";
        //$(nav).off("click");
        $(navigationTools).click(function (e) {
            e.preventDefault();
            //Enable sidebar push menu
            $("body").toggleClass('sidebar-collapse');
            $("body").toggleClass('sidebar-open');
        });

        $(".content-wrapper").click(function () {
        //Enable hide menu when clicking on the content-wrapper on small screens
            if ($(window).width() <= 767 && $("body").hasClass("sidebar-open")) {
                $("body").removeClass('sidebar-open');
            }
        });
	},
	getToolByPrefix: function(prefix, data)
	{
        switch(prefix) {
          case this.FULLSCREEN:
            return new FullscreenTool(data);
          case this.NOTIFICATION:
            return new NotificationTool(data);
          default:
            return new FullscreenTool(data);

        }
	}
});


module.exports= TheView;