require('../../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');

var ExaminationRowTemplate = require('text-loader! ../../templates/content/examinations/examination_row.template.hbs');

var popup = require('popup');
require("loader");
require("notify");

var TheView = GenericView.extend({
    tagName: "tr",
    className: "examinationRow",
    template: Handlebars.compile(ExaminationRowTemplate),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
        this.templateParams = options;
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();

		this.$el.html(this.template(this.templateParams));

		return this;
	},

	events:{
	    "mouseover td": "showResults",
	    "mouseout td": "hideResults"
	},
    showResults: function(e) {
        var self = this;

        var $td = $(e.currentTarget);
        var $tr = $td.parent();

        $tr.removeClass("row-active");
        $tr.addClass("row-active");

        var results = this.templateParams.round.turns;

        var params = {
            "results": results,
        };

        popup.show("Αποτελέσματα", "", "results", params);

        //move popup to the center of each row
        var popup_height = $("#popup_div .popup").height();
        var popup_width = $("#popup_div .popup").width();
        var tr_width = $tr.width();
        var height = $tr.offset().top - popup_height > 0 ? $tr.offset().top - popup_height : 0 ;
        var width = ($tr.offset().left + tr_width/2) - popup_width/2;
        $("#popup_div .popup").css("left", `${width}px` );
        $("#popup_div .popup").css("top", `${height - 10}px` );
        $("#popup_div .popup").css("position", "absolute" );
    },
    hideResults: function() {
        popup.hide();
        $("table.color-table").find("tr.row-active").removeClass("row-active");
    }

});

module.exports= TheView;