require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../templates/wrapper/loader.template.hbs');

var TheView = GenericView.extend({
    el: "#full-content",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
        this.render();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		var self = this;

		this.$el.html(this.template());

	},
	events:{

	}
});


module.exports= TheView;