define([],function(){
    if(!String.prototype.contains && String.prototype.search) {
        String.prototype.contains = function(s) { return this.search(s) != -1; };
    }
    return {};
});

$.fn.serializeObject = function()
{
   var o = {};
   var a = this.serializeArray();
   $.each(a, function() {
       if (o[this.name]) {
           if (!o[this.name].push) {
               o[this.name] = [o[this.name]];
           }
           o[this.name].push(this.value || '');
       } else {
           o[this.name] = this.value || '';
       }
   });
   return o;
};