require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../templates/bars/footer.template.hbs');
//var FullscreenTool = require('./tools/fullscreen.view.ts')
//var NotificationTool = require('./tools/notification.view.ts')




var TheView = GenericView.extend({
    el: $("#footer"),
    tagName: 'footer',
    FULLSCREEN: 1,
    NOTIFICATION: 2,
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
        this.render();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		this.$el.html(this.template());
	},
	events:{

	}
});


module.exports= TheView;