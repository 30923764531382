//Make sure jQuery has been loaded before app.js
if (typeof jQuery === "undefined") {
  throw new Error("AdminLTE requires jQuery");
}


(function ( $ ) {

    $.loader = {
           loadingLength: 0
    };
    $.loader.show = function(){
        $.loader.loadingLength++;
        $('body').addClass("loading");
    }
    $.loader.hide = function(){
        $.loader.loadingLength--;

        if($.loader.loadingLength <= 0){
            $('body').removeClass("loading")
            $.loader.loadingLength = 0;
        }
    }

}( jQuery ));


var Validation = function()  {
    var data = { };
    var get = function() { return this.data; }
    var set = function(value) { this.data = value; }
};