require("../../libs/backbone/backbone.ajaxq.adapter.ts");
var Handlebars = require('handlebars');

var TheView = Backbone.View.extend({
	Template: null,
	template: null,
	myDomId: null,
	domain: null,
	options: {},
	initialize: function () {

	},
	//Run method when user want to go one page back
	goBack: function()
	{
		//with this code we go to the previous page
		window.history.go(-1);
	}
});

module.exports = TheView;