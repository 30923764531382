require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/patient/patient.template.hbs');
var PersonalModel = require('../../models/patientPersonalInfo.model.ts');
var MedicalModel = require('../../models/patientMedicalInfo.model.ts');
var ExaminationModel = require('../../models/patientAutoExaminations.model.ts');
var PersonalTab = require('../../ui/patient/personal/personal.view.ts');
var ExaminationsTab = require('../../ui/patient/examinations/examinations.view.ts');
var MedicalTab = require('../../ui/patient/medical/medical.view.ts');

require("loader");
require("notify");
var Validation = require("validation");

var TheView = GenericView.extend({
    el: "#content",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	id: null,
	personalInfoUrl: String,
	medicalInfoUrl: String,
	initialize: function (options) {
        this.loadDetails(options);
	    this.self = this
	},
    loadDetails: function(options){
        this.patientId = options.id;
        this.isNew = options.isNew;
        this.isDoctor = options.isDoctor;
        this.personalModel = new PersonalModel();
        this.medicalModel = new MedicalModel();
        this.examinationModel = new ExaminationModel();
        if(this.isNew){
            this.render();
            return;
        }

        console.info("url "+window.ip)
        if(this.isDoctor == true){
            console.info("id is not null");
            this.personalModel.url = window.ip + "/api/doctor/patients/" + this.patientId + "/info/personal"
            this.medicalModel.url = window.ip + "/api/doctor/patients/" + this.patientId + "/info/medical";
            this.examinationModel.url = window.ip + "/api/doctor/patients/" + this.patientId + "/examinations";
        }
        else{
            console.info("id is null");
            this.personalModel.url = window.ip + "/api/patient/info/personal"
            this.medicalModel.url = window.ip + "/api/patient/info/medical";
            this.examinationModel.url = window.ip + "/api/patient/examinations";
        }
        this.fetch(this.personalModel, this.onPersonalSuccessFetch, this.onPersonalErrorFetch);
    },
    onPersonalSuccessFetch: function(response, xhr, status){
        this.fetch(this.medicalModel, this.onMedicalSuccessFetch, this.onMedicalErrorFetch);
    },
    onPersonalErrorFetch: function(response, xhr, status){
    },
    onMedicalSuccessFetch: function(response, xhr, status){
         this.loadAutoExamination();
    },
    onMedicalErrorFetch: function(response, xhr, status){
         this.loadAutoExamination();
    },
    loadAutoExamination: function(){
        this.fetch(this.examinationModel, this.onSuccessLoadAutoExamination, this.onErrorLoadAutoExamination);
    },
    onSuccessLoadAutoExamination: function(response, xhr, status){
        this.render()
    },
    onErrorLoadAutoExamination: function(response, xhr, status){
        this.render()
    },
    setValidation: function(){
        this.validation = new Validation();
        //Pattern firstName(is the id from input): [ {maxLength(validation): 10}]
        this.validation.addValidations(
        {
            firstName: [ {maxLength: 10} ],
            lastName: [ {maxLength: 10} ],
            fatherName: [ {maxLength: 10} ],
            mobile: [ {pattern: "phone"} ],
            phone: [ {pattern: "phone"} ],
            email: [ {pattern: "email"} ]
        });
    },
    getTitle: function(){
        if(this.isEditable())
            return "Επεξεργασία";

        return "Δημιουργία";
    },
    isEditable: function(){
        return this.patientId != null
    },
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		console.info( $.loader);

		console.info("//*** render")
		console.info(this.personalModel.toJSON())
        window.params = {firstName: this.personalModel.get("firstName"), lastName: this.personalModel.get("lastName")}
		this.$el.html(this.template({
		    firstName: this.personalModel.get("firstName"),
		    lastName: this.personalModel.get("lastName")
		}));

        this.personalTab = new PersonalTab({model: this.personalModel, patientId: this.patientId});
        this.examinationsTab = new ExaminationsTab({model: this.examinationModel, patientId: this.patientId, isDoctor: this.isDoctor});
        this.medicalTab = new MedicalTab({model: this.medicalModel, patientId: this.patientId});

	},
	events:{
        "click #save": "check",
        "click #cancel": "cancel",
	},
	cancel: function(){
	    this.goBack();
	},
	check: function(){
	  this.save(this.buildModel(), this.onSuccessSave, this.onErrorSave)
//	    if(this.validation.isAllFieldValidate()){
//	        $.loader.show();
//            this.save(this.buildModel(), this.onSuccessSave, this.onErrorSave)
//	    }
	},
	onSuccessSave: function(data, statusText, xhr){
        //if(statusText == "success")
        $.notify("Τα δεδομένα αποθηκεύτηκαν με επιτυχία", { position:"top center", className: "success"});
//	    if(!this.isEditable())
//	        this.goBack();
	},
	onErrorSave: function(model, response){
            $.notify("Τα δεδομένα δεν αποθηκεύτηκαν", { position:"top center", className: "error"});
	}
});

module.exports= TheView;
//address: (1) […]
//0: Object { street: "asdasd", number: "21", postalCode: "23", … }
//contactInfo: Object { phone: "", mobile: "", email: "d.amoutzidis@msensis.com" }
//dateOfBirth: 1586256336708
//educationLevel: "Other"
//educationLevelInfo: ""
//familialStatus: "Single"
//familialStatusInfo: ""
//fathersName: "Γεώργιος"
//firstName: "Δημήτριος"
//gender: "Male"
//hobbies: ""
//id: "5e8c59d0b8144d3701d43fdb"
//lastName: "Αμουτζίδης"
//nationality: "Ελληνική"
//occupation: ""
//referredFrom: ""
//technologyAptitudeLevel: "Medium"
//userDeviceInfo: ""