require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/admins/admins.template.hbs');
var Collection = require('./models/admins.paginated.collection.ts');
var TableBody = require('text-loader! ../../ui/admins/table/admins.table.template.hbs');
require("loader");
require("notify");

var TheView = GenericView.extend({
    el: "#content",
	template: Handlebars.compile(Template),
	tableBody: Handlebars.compile(TableBody),
	superInitialize: GenericView.prototype.initialize,
	id: null,
	personalInfoUrl: String,
	medicalInfoUrl: String,
	initialize: function (options) {
	    this.self = this
        this.render();
	    this.fetchData();
    },
    fetchData: function(){
        this.collection = new Collection();
        this.collection.url = window.ip+ '/api/admin/admins';
        this.collection.on( 'sync', this.onSuccess, this );
        this.collection.on( 'error', this.onError, this );
        this.collection.getFirstPage();
    },
    onSuccess: function(response){
        this.renderAdmins();
    },
    onError: function(response, xhr, options){
    },
    renderAdmins: function(){
        var container = $('#admins-table tbody');
        if(this.collection.models.length > 0 )
            container.html(this.tableBody({ items: this.collection.models } ));
        else
            container.html("<tr class='empty'><td colspan='5'>Δε βρέθηκαν αποτελέσματα...</td></tr>");
    },
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();

		this.$el.html(this.template({}));
	},
	events:{
        "click #admins-table > tbody > tr": "navigateToAdmin"
	},
	navigateToAdmin: function(element){
	    var id = $(element.currentTarget).data("id");
        Backbone.history.navigate('#Admins/' + id, { trigger : true } );
	}
});

module.exports= TheView;
//address: (1) […]
//0: Object { street: "asdasd", number: "21", postalCode: "23", … }
//contactInfo: Object { phone: "", mobile: "", email: "d.amoutzidis@msensis.com" }
//dateOfBirth: 1586256336708
//educationLevel: "Other"
//educationLevelInfo: ""
//familialStatus: "Single"
//familialStatusInfo: ""
//fathersName: "Γεώργιος"
//firstName: "Δημήτριος"
//gender: "Male"
//hobbies: ""
//id: "5e8c59d0b8144d3701d43fdb"
//lastName: "Αμουτζίδης"
//nationality: "Ελληνική"
//occupation: ""
//referredFrom: ""
//technologyAptitudeLevel: "Medium"
//userDeviceInfo: ""