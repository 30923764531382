require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../templates/bars/side.template.hbs');
require("loader");

var TheView = GenericView.extend({
    el: "#side",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
	//console.log(options);
    this.user = options;
//	    this.langs = {
//            "el": "gr",
//            "en": "gb"
//        };

//		this.userData = options.data;
//		this.pageHeader = options.pageHeader;
//		this.numberPrivilegeLevel = this.privilegeLevelToNumber(this.userData.privilegeLevel)
        this.render();

	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		var self = this;

		this.$el.html(this.template({
		    user: this.user
		}));

		this.initializeTools();
		this.setActiveMenu();
	},
	setActiveMenu: function(){
        var active = window.location.href.split("#")[1];
        $('[href="#'+active+'"]').parent().addClass("active");
	},
	events:{
	    "click .sidebar-menu li a": "navigateTo"
	},
	navigateTo: function(e){
	    $(".sidebar-menu li.active").removeClass("active");
	    var menu = $(e.currentTarget);
        menu.parent().addClass("active")

	},
	initializeTools: function(){
	    var menu = ".sidebar";
        $("li a", $(menu)).click(function (e) {
            //Get the clicked link and the next element
            var $this = $(this);
            var checkElement = $this.next();

            //Check if the next element is a menu and is visible
            if ((checkElement.is('.treeview-menu')) && (checkElement.is(':visible'))) {
                //Close the menu
                checkElement.slideUp('normal', function () {
                    checkElement.removeClass('menu-open');
                });
                checkElement.parent("li").removeClass("active");
            }//If the menu is not visible
            else if ((checkElement.is('.treeview-menu')) && (!checkElement.is(':visible'))) {
                //Get the parent menu
                var parent = $this.parents('ul').first();
                //Close all open menus within the parent
                var ul = parent.find('ul:visible').slideUp('normal');
                //Remove the menu-open class from the parent
                ul.removeClass('menu-open');
                //Get the parent li
                var parent_li = $this.parent("li");

                //Open the target menu and add the menu-open class
                checkElement.slideDown('normal', function () {
                    //Add the class active to the parent li
                    checkElement.addClass('menu-open');
                    parent.find('li.active').removeClass('active');
                    parent_li.addClass('active');
                });
            }
            //if this isn't a link, prevent the page from being redirected
                if (checkElement.is('.treeview-menu')) {
            e.preventDefault();
            }
        });
	}
});


module.exports= TheView;