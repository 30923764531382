require('../../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../../prototypes/generic.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader!../../../templates/bars/tools/user_profile.template.hbs');

var TheView = GenericView.extend({
    el: "#topBarUserProfile",
    tagName: "li",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
	    this.user = options;
        this.render();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		this.$el.html(this.template( this.user ));
	},
	events:{
        "click #logout": "logout"
	},
    logout: function(){
        console.info(document.cookie);
        $.ajaxDefaultQ({
            url: window.ip + '/api/user/logout',
            type: 'POST',
        }).done(function(response, status, xhr){
            window.open('./','_self');
        })
        .fail(function(response) {
            window.open('./','_self');
        });
    }
});


module.exports= TheView;