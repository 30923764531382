var Backbone = require("../../../../libs/backbone/backbone");
var GenericModel = require("../../../prototypes/generic.model.ts");

var Model = GenericModel.extend(
{
    defaults : {
       id: null,
       name: "",
       description: "",
       address: null,
       doctors: [],
       supportStaff: []
    }
});

module.exports = Model;