require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.collection.view.ts');
var Handlebars = require('handlebars');
var HandlebarsHelpers = require('../../../libs/handlebars/handlebars-helpers.ts');
var Template = require('text-loader! ../../templates/content/patients.template.hbs');
var TableBody = require('text-loader! ../../templates/partials/user.body.partial.template.hbs');
var PatientsCollections = require('../../models/patients.paginated.collection.ts');
var Pagination = require('../parts/pagination.view.ts');
var Search = require('../parts/search.view.ts');
require("loader");

var TheView = GenericView.extend({
    el: "#content",
	template: Handlebars.compile(Template),
	tableBody: Handlebars.compile(TableBody),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
	   this.render();
       this.fetchData();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		this.$el.html(this.template());
		//this.renderSearch();
	},
	fetchData: function(){
	    var self = this;
        $.loader.show();
	     $.ajaxDefaultQ({
            url: window.ip + '/api/doctor/patients',
            type: 'GET'
        }).done(function(response, status, xhr){
           $.loader.hide();
           self.renderPatients(response.data);
        })
        .fail(function(response) {
          $.loader.hide();
        })
	},
	refreshData: function(){
        this.patientCollection.fetch();
	},
	onSuccessFetchData: function(response){
        $.loader.hide();
	    console.log(response);
        this.renderPatients(response.models)
	    //this.renderPagination();
	},
	onErrorFetchData: function(response, xhr, options){
        $.loader.hide();
	},
	events:{
	    "click #patients-table tbody tr": "patientClick",
        "click td > .action": "deletePatient"
	},
	fetchSearchableData: function(searchable){
	    //console.log(searchable);
        //console.log(this);
        if(searchable == "")
            this.fetchData()
        else{
            var url = '/rest/user?q=' + encodeURIComponent("lastName="+searchable) + '&filter=' + encodeURIComponent('role=Patient');
            this.collection = this.fetch(Collection, url,  this.onSuccessFetchSearchableData, this.onErrorFetchSearchableData);
        }
	},
	onSuccessFetchSearchableData: function(response){
	    $.loader.hide();
        this.renderPatients(response.models);
        //this.renderPagination();
	},
    onErrorFetchSearchableData: function(response){
        $.loader.hide();
        this.renderPatients(response.models);
        //this.renderPagination();
    },
	patientClick: function(e){
        var id = $(e.currentTarget).data("id");
        Backbone.history.navigate('#Patient/' + id, { trigger : true } );
        //window.location.href = "#Organization/" + $(e.currentTarget).parent().data("id");
	    //console.log("clicked "+ patientId);
	},
	deletePatient: function(e){
	    e.stopPropagation();
	    var index = this.getDataIndex(e);
	    this.confirmToDelete(this.collection.models[index], this.refreshData);
	},
	showLoader: function(){
	    $("#patients-table tbody").html(' <tr><td colspan="6" class="loader-placeholder"><div class="loader"></div></td></tr>');
	},
	renderPatients: function(patients){
	    var container = $('#patients-table tbody');
	    console.info(patients)
	    if(patients.length > 0 )
            container.html(this.tableBody({ patients: patients } ));
        else
            container.html("<tr class='empty'><td colspan='5'>Δε βρέθηκαν αποτελέσματα...</td></tr>");
	},
	renderPagination: function(){
        this.pagination = new Pagination({ collection : this.collection });
        this.pagination.render();
        $( '.box-footer' ).html( this.pagination.$el );
	},
	renderSearch: function(){
        this.search = new Search();
        this.search.on(this.fetchSearchableData, this);
        $( '#box1' ).html( this.search.$el);
	},
	getPatientIdData: function(e){
	    var element = $(e.currentTarget);
        //console.log(element);
	    while(!element.data("id")) {
	        if(element[0].tagName.toLowerCase() =="tr")
	            break;
	        element = element.parent();
	    }

	    return element.data("id");
	},
	getDataIndex: function(e){
        var element = $(e.currentTarget);
        while(!element.data("index")) {
            if(element[0].tagName.toLowerCase() =="tr")
                break;
            element = element.parent();
        }
        return element.data("index");
    }
});


module.exports= TheView;