require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.collection.view.ts');
var Handlebars = require('handlebars');
var HandlebarsHelpers = require('../../../libs/handlebars/handlebars-helpers.ts');
var Template = require('text-loader! ../../templates/content/associate.template.hbs');
var TableBody = require('text-loader! ../../templates/partials/user.body.partial.template.hbs');
var Search = require('../parts/search.view.ts');
//var PatientCollection = require('../../models/patient.paginated.collection.ts');
require("loader");
require("notify");

var TheView = GenericView.extend({
    el: "#content",
	template: Handlebars.compile(Template),
	tableBody: Handlebars.compile(TableBody),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
	   this.render();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.$el.html(this.template());
	},
	events:{
	    "click .btn-associate": "associate"
	},
	associate: function(){
	    var code = $("#code").val().trim()
        $.loader.show()

        var dataAccess = {
            "permissionToken": code
        }

        $.ajaxDefaultQ({
            url: window.ip + '/api/doctor/patients',
            type: 'POST',
            data: JSON.stringify(dataAccess),
            contentType: "application/json; charset=utf-8",
            dataType: "json"
        }).done(function(response, status, xhr){
            if(status == "success"){
                $.notify("Έχετε εξουσιοδοτηθεί για τον ασθενή "+response.data.user.firstName+ " "+response.data.user.lastName, { position:"top center", className: "success"});
            }
        })
        .fail(function(response) {
            $.notify("Ο κωδικός εξουσιοδότησεις είναι λάθος", { position:"top center", className: "error"});
        }).always(function(){
            $.loader.hide()
        });


	}
});


module.exports= TheView;