var Backbone= require("backbone");
var UserModel = require("./user.model.ts");
require("backbonePaginator");
require("compat");

var PaginatedCollection = Backbone.PageableCollection.extend({
	mode: "server",
	model: UserModel,
	// Initial pagination states
	state:{
		pageSize: 10,
		sortKey: "lastName",
		firstPage: 0,
		page: 0
	},
	// Be sure to change this based on how your results are structured
	parse : function ( response ){
		var data = response.data;
		//this.totalPages = response.data.totalPages;
		//this.state.lastPage = this.totalPages - 1;
		return data;
	},
	// Used by the template
	info : function ()
	{
		var info =
		{
			firstPage : this.state.firstPage,
			page : this.state.currentPage,
			lastPage : this.state.lastPage,
			perPage : this.state.pageSize
		};
		this.information = info;
		return info;
	}
});
module.exports = PaginatedCollection;