require("../../libs/backbone/backbone.ajaxq.adapter.ts");
var Handlebars = require('handlebars');

var TheView = Backbone.View.extend({
	Template: null,
	template: null,
	myDomId: null,
	domain: null,
	options: {},
	initialize: function () {

	},
	fetch: function(Collection, url, successCallback, errorCallback){
        $.loader.show();
        var collection = new Collection();
        collection.url = url;
        if(successCallback != null)
            collection.on( 'sync', successCallback, this );
        if(errorCallback != null)
            collection.on( 'error', errorCallback, this );
       collection.getFirstPage();
       return collection;
    },
    confirmToDelete: function(model, callback){
        var result = confirm("Θέλετε να σβήσετε τον "+model.attributes.lastName);
        if(result){
            var self = this;
            $.loader.show();
            model.destroy(
            {
                success: function(response){  $.loader.hide(); if(callback!=null) callback.apply(self); },
                error: function(response){  $.loader.hide(); if(callback!=null) callback.apply(self); }
            });
        }
    }
});

module.exports = TheView;