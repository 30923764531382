require('../../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/patient/medical/medical.template.hbs');
require("loader");
require("notify");
var Validation = require("validation");

var TheView = GenericView.extend({
    el: "#tab2",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
	    this.model = options.model;
	    this.patientId = options.patientId
        this.render();
	},
    loadDetails: function(options){
    },
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
        var data = {  isPatient: this.patientId == null};
//        var json = model: this.model.toJSON(),
        console.log( this.model);
        if(this.model.get("inheritedDiseases") != null){
            console.log( this.model.get("inheritedDiseases")[0]);
            var data = {
                model: this.model.toJSON(),
                inheritedDiseases: this.model.get("inheritedDiseases")[0],
                otherDiseases: this.model.get("otherDiseases")[0],

            }
        }


		this.$el.html(this.template(data));
	},
	events:{
        "click #addPersonalHistory": "addPersonalHistory",
        "click #addFamilyHistory": "addFamilyHistory"
	},
	addPersonalHistory: function(){
	    var newRow = '<tr>';
        newRow += '<td width="20%">' + this.typeDropdown() + '</td>';
        newRow += '<td width="30%"><input type="text" class="form-control"></input></td>';
        newRow += '<td width="5 0%"><textarea type="text" class="form-control" data-id="null" rows="2" cols="10"></textarea></td>';
	    newRow += '</tr>';
        console.info(newRow);
        $('#personalHistory > tbody').append(newRow);
	},
	typeDropdown: function(){
	    var dropdown = "<select>";
	    dropdown += "<option value='General'>Γενικό</option>";
	    dropdown += "<option value='Ocular'>Οφθαλμολογικό</option>";
        dropdown += "</select>";
        return dropdown;
	},
	addFamilyHistory: function(){

	},
	check: function(){
        this.save(this.buildModel(), this.onSuccessSave, this.onErrorSave)
    },
    onSuccessSave: function(data, statusText, xhr){
        $.notify("Τα δεδομένα αποθηκεύτηκαν με επιτυχία", { position:"top center", className: "success"});
    },
    onErrorSave: function(model, response){
        $.notify("Τα δεδομένα δεν αποθηκεύτηκαν", { position:"top center", className: "error"});
    },
	buildModel: function(){

        if(this.model.get("patientId") == null){
            this.model.set("patientId", this.patientId);
            this.model.set("id", null);
        }
        this.model.set("familyHistory", []);
        this.model.set("inheritedDiseases", []);
        this.model.set("personalHistory", []);
        this.model.set("visitations", []);

        var inheritedDiseases = $("#inheritedDiseases").val().trim()
        this.model.set("inheritedDiseases", [inheritedDiseases]);
//        var otherDiseases = $("#otherDiseases").val().trim()
        this.model.set("otherDiseases", []);
        return this.model;
	}
});

module.exports= TheView;
//address: (1) […]
//0: Object { street: "asdasd", number: "21", postalCode: "23", … }
//contactInfo: Object { phone: "", mobile: "", email: "d.amoutzidis@msensis.com" }
//dateOfBirth: 1586256336708
//educationLevel: "Other"
//educationLevelInfo: ""
//familialStatus: "Single"
//familialStatusInfo: ""
//fathersName: "Γεώργιος"
//firstName: "Δημήτριος"
//gender: "Male"
//hobbies: ""
//id: "5e8c59d0b8144d3701d43fdb"
//lastName: "Αμουτζίδης"
//nationality: "Ελληνική"
//occupation: ""
//referredFrom: ""
//technologyAptitudeLevel: "Medium"
//userDeviceInfo: ""