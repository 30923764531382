require('../../libs/backbone/backbone.ajaxq.adapter.ts');

var TheModel = Backbone.Model.extend({
    ip: "http://life4lv-cpanel.msensis.com",
	initialize: function(attributes, options) {
        this.bind("error", this.defaultErrorHandler);
    },
	parse: function(response)
	{
		if(!response.data)
			return response;

		return response.data;
	},
    sync: function(method, model, options) {
        options.xhrFields = {
                 withCredentials: true
             };

        return Backbone.sync.call(this, method, model, options);
    },
	defaultErrorHandler: function(model, error) {
	 	document.cookie = "status=" + error.status;
		if (error.status == 401) {
			window.open('./','_self');
		}else if(error.status == 403){
			//$.notify(i18n.t("user.not_allowed"), { position:"top center", className: "error"});
			$.ajaxq.abort("DefaultQueue");
			window.open('./','_self');
		}else {
//		    var str = "";
//            var causes = error.responseJSON.error.causes;
//
//            for(i=0; i<causes.length; i++)
//                str += causes[i].message +"\n";
//
//            $.notify(str, { position:"top center", className: "error" });
		}
	},
//	save: function (key, val, options) {
//         this.beforeSave(key, val, options);
//         return Backbone.Model.prototype.save.call(this, key, val, options);
//       },
//
//       beforeSave: function (key, val, options) {
//
//             console.info("//////////");
//             console.info(key);
//             console.info(val);
//             console.info(options);
//       },
 	attributeUrl: function (attributeName) {
		return this.urlRoot + '/' + this.get("id") + "/" + attributeName;
	},
	attributeNullUrl: function(attributeName){
		return this.urlRoot+'/null/'+this.get("id")+"/"+attributeName;
	},
	saveAttribute: function (attributeName, value, callback) {
		var self = this;
		var url = value == null ? self.attributeNullUrl(attributeName) : self.attributeUrl(attributeName);
		console.info("url = "+url);
		$.ajaxDefaultQ({
			url: url,
			type: value == null ? 'POST' : 'PUT',
			contentType: 'application/json',
			data: value == null ? JSON.stringify("") : JSON.stringify(value),
			dataType: 'json'
		}).done(function (data) {
			callback(attributeName, value, data.outcome);
		}).fail(function (data) {
			callback(attributeName, value, "NotDone");
		});
	}
});

module.exports = TheModel;