var Backbone = require("../../../../libs/backbone/backbone");
var GenericModel = require("../../../prototypes/generic.model.ts");

var Model = GenericModel.extend(
{
    defaults : {
       id : null,
       firstName: null,
       lastName: null,
       activated: null,
       email: null,
       authority: "Admin"
    }
});

module.exports = Model;