require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/patient_new/patient.template.hbs');
var DetailsView = require('../../ui/patient_new/details/details.view.ts');

require("loader");
require("notify");
var Validation = require("validation");

var TheView = GenericView.extend({
    el: "#content",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	id: null,
	personalInfoUrl: String,
	medicalInfoUrl: String,
	initialize: function (options) {
        this.parseParams(options);
        this.render();
        console.log(window.location.pathname);
	},
	parseParams: function(options){
	    var param1 = options.param1;
	    console.log(options);
	    if(param1 != null){
	        if(this.isTabType(param1))
	            this.type = param1;
	        else{
	            this.type = "Details"
	            this.id = param1;
            }
	    }else{
	         this.type = "Details";
	    }

	    var param2 = options.param2;
	    if(param2 != null){
	        this.type = param2;
	    }

        this.path = "#Patient";
        console.log(this.id+" "+this.type);
        if(this.id != null){
            this.path += "/" + this.id
        }
	},
    isTabType: function(param){
        if(param == "Details"){
            return true
        }else if(param == "History"){
            return true
        }else if(param == "Examinations"){
            return true
        }
        return false
    },
    buildView: function(){
        if(this.type == "Details"){
            return new DetailsView({id: this.id});
        }else if(this.type == "History"){
            return null;
        }else
            return null;

    },
    onPersonalSuccessFetch: function(response, xhr, status){
        this.fetch(this.medicalModel, this.onMedicalSuccessFetch, this.onMedicalErrorFetch);
    },
    onPersonalErrorFetch: function(response, xhr, status){
    },
    onMedicalSuccessFetch: function(response, xhr, status){
         this.loadAutoExamination();
    },
    onMedicalErrorFetch: function(response, xhr, status){
         this.loadAutoExamination();
    },
    loadAutoExamination: function(){
        this.fetch(this.examinationModel, this.onSuccessLoadAutoExamination, this.onErrorLoadAutoExamination);
    },
    onSuccessLoadAutoExamination: function(response, xhr, status){
        this.render()
    },
    onErrorLoadAutoExamination: function(response, xhr, status){
        this.render()
    },
    setValidation: function(){
        this.validation = new Validation();
        //Pattern firstName(is the id from input): [ {maxLength(validation): 10}]
        this.validation.addValidations(
        {
            firstName: [ {maxLength: 10} ],
            lastName: [ {maxLength: 10} ],
            fatherName: [ {maxLength: 10} ],
            mobile: [ {pattern: "phone"} ],
            phone: [ {pattern: "phone"} ],
            email: [ {pattern: "email"} ]
        });
    },
    getTitle: function(){
        if(this.isEditable())
            return "Επεξεργασία";

        return "Δημιουργία";
    },
    isEditable: function(){
        return this.patientId != null
    },
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
//		console.info( $.loader);
//
//		console.info("//*** render")
//		console.info(this.personalModel.toJSON())
//        window.params = {firstName: this.personalModel.get("firstName"), lastName: this.personalModel.get("lastName")}

		this.$el.html(this.template({
		    firstName: "test",
		    lastName: "testopoulos",
		    path: this.path,
		    type: this.type
		}));

        this.view = this.buildView();

//        this.personalTab = new PersonalTab({model: this.personalModel, patientId: this.patientId});
//        this.examinationsTab = new ExaminationsTab({model: this.examinationModel, patientId: this.patientId});
//        this.medicalTab = new MedicalTab({model: this.medicalModel, patientId: this.patientId});

	},
	events:{
        "click #save": "check",
        "click #cancel": "cancel",
	},
	cancel: function(){
	    this.goBack();
	},
	check: function(){
	  this.save(this.buildModel(), this.onSuccessSave, this.onErrorSave)
//	    if(this.validation.isAllFieldValidate()){
//	        $.loader.show();
//            this.save(this.buildModel(), this.onSuccessSave, this.onErrorSave)
//	    }
	},
	onSuccessSave: function(data, statusText, xhr){
        //if(statusText == "success")
        $.notify("Τα δεδομένα αποθηκεύτηκαν με επιτυχία", { position:"top center", className: "success"});
//	    if(!this.isEditable())
//	        this.goBack();
	},
	onErrorSave: function(model, response){
            $.notify("Τα δεδομένα δεν αποθηκεύτηκαν", { position:"top center", className: "error"});
	}
});

module.exports= TheView;
//address: (1) […]
//0: Object { street: "asdasd", number: "21", postalCode: "23", … }
//contactInfo: Object { phone: "", mobile: "", email: "d.amoutzidis@msensis.com" }
//dateOfBirth: 1586256336708
//educationLevel: "Other"
//educationLevelInfo: ""
//familialStatus: "Single"
//familialStatusInfo: ""
//fathersName: "Γεώργιος"
//firstName: "Δημήτριος"
//gender: "Male"
//hobbies: ""
//id: "5e8c59d0b8144d3701d43fdb"
//lastName: "Αμουτζίδης"
//nationality: "Ελληνική"
//occupation: ""
//referredFrom: ""
//technologyAptitudeLevel: "Medium"
//userDeviceInfo: ""