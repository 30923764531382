var Backbone= require("backbone");
var Model = require("./doctor.model.ts");
require("backbonePaginator");

var PaginatedCollection = Backbone.PageableCollection.extend({
	mode: "server",
	model: Model,
	state:
	{
		size: 10,
		sortKey: "-id",
		firstPage: 0,
		page: 0
	},
	// Be sure to change this based on how your results are structured
	parse : function ( response )
	{

//		this.totalPages = response.data.pagesCount; //todo: ayto na ksereis tha xreiastei gia to pagination
//		this.state.lastPage = this.totalPages - 1; //this too

        if(!response.data)
            return response;

		return response.data;
	},
	// Used by the template
	info : function ()
	{
		var info =
		{
			firstPage : this.state.firstPage,
			page : this.state.currentPage,
			lastPage : this.state.lastPage,
			perPage : this.state.pageSize
		};
		this.information = info;
		return info;
	}
});
module.exports = PaginatedCollection;