require('../../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');
var ColorAppTemplate = require('text-loader! ../../templates/content/examinations/colorapp.template.hbs');
var EDTRSTemplate = require('text-loader! ../../templates/content/examinations/edtrs.template.hbs');
var GlareTemplate = require('text-loader! ../../templates/content/examinations/glare.template.hbs');
var ContrastTemplate = require('text-loader! ../../templates/content/examinations/contrast.template.hbs');
var IshiharaTemplate = require('text-loader! ../../templates/content/examinations/ishihara.template.hbs');
var AmslerTemplate = require('text-loader! ../../templates/content/examinations/amsler.template.hbs');
var ReadingRateTemplate = require('text-loader! ../../templates/content/examinations/reading.rate.template.hbs');
var ExaminationModel = require('../../../models/examination.model.ts');
var PersonalModel = require('../../../models/patientPersonalInfo.model.ts');
var popup = require('popup');
require("loader");
require("notify");


var ExaminationRowView = require('./examination_row.view.ts');

var TheView = GenericView.extend({
    el: "#content",
	superInitialize: GenericView.prototype.initialize,
	correctTries: 0,
	totalTries: 0,
	redRounds: null,
	greenRounds: null,
	blueRounds: null,
	initialize: function (options) {
	    this.correctTries = 0;
        this.totalTries = 0;
	    this.redRounds = null;
        this.greenRounds = null;
        this.blueRounds = null;

        this.loadDetails(options);
	},
	loadProperTemplate: function(){
	    if(this.type == "ColorApp"){
            this.template = Handlebars.compile(ColorAppTemplate);
	    }else if(this.type == "EDTRS" || this.type == "VisualAcuity1M"){
            this.template = Handlebars.compile(EDTRSTemplate);
	    }else if(this.type == "Glare"){
	        this.template = Handlebars.compile(GlareTemplate);
	    }else if(this.type == "Contrast"){
            this.template = Handlebars.compile(ContrastTemplate);
        }else if(this.type == "Ishihara"){
            this.template = Handlebars.compile(IshiharaTemplate);
        }else if(this.type == "Amsler"){
            this.template = Handlebars.compile(AmslerTemplate);
        }else if(this.type == "ReadingRate"){
            this.template = Handlebars.compile(ReadingRateTemplate);
        }
	},
    loadDetails: function(options){
        this.type = options.type;
        this.examinationId = options.examinationId;
        this.patientId = options.patientId;

        this.loadProperTemplate();
        this.model = new ExaminationModel();
        this.personalModel = new PersonalModel();

        if(this.patientId != null) {
            this.model.url = window.ip + "/api/doctor/patients/" + this.patientId + "/examinations/"+this.examinationId;
            this.personalModel.url = window.ip + "/api/doctor/patients/" + this.patientId + "/info/personal";
        } else {
            this.model.url = window.ip + "/api/patient/examinations/"+this.examinationId;
            this.personalModel.url = window.ip + "/api/patient/info/personal"
        }
        //this.fetch(this.model, this.onSuccess, this.onError);
        this.fetch(this.personalModel, this.onPersonalSuccessFetch, this.onPersonalErrorFetch);
    },
    onPersonalSuccessFetch: function(response, xhr, status){
        this.fetch(this.model, this.onSuccess, this.onError);
    },
    onPersonalErrorFetch: function(response, xhr, status){
    },
    onSuccess: function(data, statusText, xhr){
        if(this.type == "ColorApp"){
            this.redRounds = this.model.get("metrics").red.rounds;
            this.greenRounds = this.model.get("metrics").green.rounds;
            this.blueRounds = this.model.get("metrics").blue.rounds;

            this.calculateCorrectTries();
            this.calculateTotalTries();
        }

       this.render();

       this.loadRounds();
    },
    onError: function(model, response){

       this.render();
    },
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		console.info( this.model.toJSON());

		this.$el.html(this.template({
		        result: this.model.toJSON(),
		        correctTries: this.correctTries,
		        totalTries: this.totalTries,
		        personalInfo: this.personalModel.toJSON(),
		}));
	},

	events:{

	},
    loadRounds: function() {
        var self = this;

        var colors = [
            {round: this.redRounds, id: "#examinationsRed"},
            {round: this.greenRounds, id: "#examinationsGreen"},
            {round: this.blueRounds, id: "#examinationsBlue"}
        ];

        $.each(colors, function(colorIndex, color) {
            $.each(color.round, function(index, round) {
                var rowView = new ExaminationRowView({index:index, round: round});
                self.$el.find(color.id).append( rowView.render().el );
            });
        });

    },
    calculateCorrectTries: function() {
        var self = this;

        var colorsRound = [this.redRounds, this.greenRounds, this.blueRounds];

        $.each(colorsRound, function(index, color) {
            $.each(color, function(i, round){
                self.correctTries += round.corrects;
            });
        });

        return self.correctTries;
    },
    calculateTotalTries: function() {
        var self = this;

        var colorsRound = [this.redRounds, this.greenRounds, this.blueRounds];

        $.each(colorsRound, function(index, color) {
            $.each(color, function(i, round){
                self.totalTries += round.corrects + round.wrongs;
            });
        });

        return this.totalTries;
    }
});

module.exports= TheView;
//address: (1) […]
//0: Object { street: "asdasd", number: "21", postalCode: "23", … }
//contactInfo: Object { phone: "", mobile: "", email: "d.amoutzidis@msensis.com" }
//dateOfBirth: 1586256336708
//educationLevel: "Other"
//educationLevelInfo: ""
//familialStatus: "Single"
//familialStatusInfo: ""
//fathersName: "Γεώργιος"
//firstName: "Δημήτριος"
//gender: "Male"
//hobbies: ""
//id: "5e8c59d0b8144d3701d43fdb"
//lastName: "Αμουτζίδης"
//nationality: "Ελληνική"
//occupation: ""
//referredFrom: ""
//technologyAptitudeLevel: "Medium"
//userDeviceInfo: ""