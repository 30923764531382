﻿require('../../libs/backbone/backbone.ajaxq.adapter.ts');
var HeaderView = require('../views/bars/header.view.ts');
var SideView = require('../views/bars/side.view.ts');
var FooterView = require('../views/bars/footer.view.ts');
var DashBoardView = require('../views/dashboard.view.ts');
var PatientsView = require('../views/content/patients.view.ts');
var PatientView = require('../ui/patient/patient.view.ts');
var PatientNewsView = require('../ui/patient_new/patient.view.ts');
var ClinicAdminsView = require('../ui/clinic_admins/clinic_admins.view.ts');
var ClinicAdminView = require('../ui/clinic_admin/clinic_admin.view.ts');
var ExaminationView = require('../views/content/examinations/examination.view.ts');
var AccountView = require('../ui/account/account.view.ts');
var LoaderView = require('../views/wrapper/loader.view.ts');
var ProfileView = require('../views/content/profile.view.ts');
var ExaminationsView = require('../ui/examinations/examinations.view.ts');
var LoginView = require('../views/login.view.ts');
var EmptyView = require('../views/empty.view.ts');
var AssociateView = require('../views/content/associate.view.ts');
var AdminsView = require('../ui/admins/admins.view.ts');
var AdminView = require('../ui/admin/admin.view.ts');
var ActivationView = require('../ui/activation/activation.view.ts');
var RestorationView = require('../ui/restoration/restoration.view.ts');
var ClinicsView = require('../ui/clinics/clinics.view.ts');
var ClinicView = require('../ui/clinic/clinic.view.ts');
var DoctorsView = require('../ui/doctors/doctors.view.ts');
var DoctorView = require('../ui/doctor/doctor.view.ts');
require("loader");

var TheRouter = Backbone.Router.extend({
	routes: {
		"": "defaultRoute",
		"Login": "loginRoute",
		"Dashboard": "dashBoardRoute",
		"Patients": "patientsRoute",
		"Patient(/:id)": "patientRoute",
		//"Patient(/:param1)(/:param2)": "patientRoute",
		"Patient/New": "patientNewRoute",
		"Examination(/:type)(/:examinationId)(/:patientId)": "examinationRoute",
		"Examinations": "examinationsRoute",
		"Profile": "profileRoute",
		"Empty": "emptyRoute",
		"Associate": "associateRoute",
		"ClinicAdmins(/:id)": "clinicAdminsRoute",
		"Clinics(/:id)": "clinicsRoute",
		"Admins(/:id)": "adminsRoute",
        "Activation(/:token)": "activationRoute",
        "Restore(/:token)": "restorationRoute",
        "Doctors(/:id)": "doctorsRoute",
        "Account": "accountRoute"
	},
	loggedIn: false,
	userData: null,
	activeMenu: "",
	initialize: function (options) {
	    this.userInfo = options.data;
        this.loggedIn = options.loggedIn;

        if(this.loggedIn){
            $.ajaxSetup({
                beforeSend: function (xhr) {
                //xhr.setRequestHeader('Authorization', "Bearer " + self.token);
                    xhr.setRequestHeader('systemTime', new Date().getTime());
                },
                error: function (xhr, exception) {
                    //console.info("ERROR at AJAX ROUTER");
                    document.cookie = "status=" + xhr.status;
                    if (xhr.status == 401) {
                        window.open('./','_self');
                    }else if(xhr.status == 403){
                        //$.ajaxq.abort("DefaultQueue");
                        //window.open('./','_self');
                    }
                }
            });
        }

        this.defaultRoute()
	},
	isAuthenticateUser: function(){
		if(!this.loggedIn){
            window.open('./','_self');
        }
        return this.loggedIn;
	},
	defaultRoute: function () {
	  	if(!this.loggedIn){
            this.navigate('Login', {trigger: true});
        }else{
            if(this.userInfo.role == "Patient")
                this.navigate('Patient', {trigger: true});
            else if(this.userInfo.role == "Doctor")
                this.navigate('Patients', {trigger: true});
            else if(this.userInfo.role == "Admin")
                this.navigate('ClinicAdmins', {trigger: true});
            else if(this.userInfo.role == "ClinicAdmin")
                this.navigate('Clinics', {trigger: true});
        }
	},
	clinicAdminsRoute: function(id){
	    if(this.isAuthenticateUser()){
	        if(!id)
                this.displayContentView(ClinicAdminsView);
             else
                this.displayContentView(ClinicAdminView, {id: id});
        }
	},
	clinicsRoute: function(id){
	    if(this.isAuthenticateUser()){
	        if(!id)
                this.displayContentView(ClinicsView);
             else
                this.displayContentView(ClinicView, {id: id});
        }
	},
	doctorsRoute: function(id){
	    if(this.isAuthenticateUser()){
	        if(!id)
                this.displayContentView(DoctorsView);
             else
                this.displayContentView(DoctorView, {id: id});
        }
	},
	activationRoute: function(token){
	    if(!this.loggedIn)
            this.displayFullScreenView(ActivationView, {token: token});
        else
            this.defaultRoute();
	},
	restorationRoute: function(token){
	    if(!this.loggedIn)
            this.displayFullScreenView(RestorationView, {token: token});
        else
            this.defaultRoute();
	},
	adminsRoute: function(id){
	    if(this.isAuthenticateUser()){
	        if(!id)
                this.displayContentView(AdminsView);
             else
                this.displayContentView(AdminView, {id: id});
        }
	},
    loginRoute: function(){
        this.displayFullScreenView(LoginView);
    },
	dashBoardRoute: function(){
	    if(this.isAuthenticateUser()){
            this.displayContentView(DashBoardView);
        }
	},
	associateRoute: function(){
	    if(this.isAuthenticateUser()){
            this.displayContentView(AssociateView);
        }
	},
	patientsRoute: function(){
        if(this.isAuthenticateUser()){
            this.displayContentView(PatientsView);
        }
	},
	patientNewRoute: function(param1, param2){
        if(this.isAuthenticateUser()){
            this.displayContentView(PatientNewsView, {param1: param1, param2: param2, isNew: false});
        }
	},
    patientRoute: function(id){
        if (id != null) {
            var isDoctor = true;
            var patientId = id;
        } else {
            var isDoctor = false;
            var patientId = this.userInfo.id;
        }

        if(this.isAuthenticateUser()){
            this.displayContentView(PatientView, {id: patientId, isNew: false, isDoctor: isDoctor});
        }
    },
	examinationRoute: function(type, examinationId, patientId){
        if(this.isAuthenticateUser()){
            data = {type: type, examinationId: examinationId, patientId: patientId};
            this.displayContentView(ExaminationView, data);
        }
	},
//	patientNewRoute: function(id){
//        if(this.isAuthenticateUser()){
//            this.displayContentView(PatientView, {id: id, isNew: true});
//        }
//	},
	profileRoute: function(){
	    if(this.isAuthenticateUser()){
            this.displayContentView(ProfileView);
        }
	},
	accountRoute: function(){
	    if(this.isAuthenticateUser()){
            this.displayContentView(AccountView);
        }
	},
	examinationsRoute: function(){
	    if(this.isAuthenticateUser()){
            this.displayContentView(ExaminationsView);
        }
	},
	emptyRoute: function(){
  		this.createBar();
		this.view = new EmptyView();
	},
	renderBaseView: function(baseTemplate){
//		var View = ViewFactory(baseTemplate);
//		this.view = new View(ViewConfiguration);
//		this.view.render();
	},
	createBar: function(){
		this.header = new HeaderView(this.userInfo);
		this.side = new SideView(this.userInfo);
		this.footer = new FooterView();
	},
	displayContentView: function(View, data = {}){
  		this.initializeBarIfNeeded()
        this.clearView();
        this.view = new View(data);
	},
	initializeBarIfNeeded: function(){
        if(this.header == null){
            //console.log("create bar");
	        this.createBar();
	        var loader = new LoaderView();
        }
	},
    displayFullScreenView: function(View, data = {}){
        this.clearBars();
        $(".full-content-wrapper").css("display", "block");
            this.clearView();
        this.view = new View(data);
    },
	clearView: function(){
        if(this.view){
            $.ajaxq.abort("DefaultQueue");
            this.view.stopListening();
            this.view.$el.empty();
            if(this.view.clearView)
                this.view.clearView();
            this.view.undelegateEvents();
            delete this.view;
        }
    },
    clearBars: function(){
        this.clearBar(this.header);
        this.clearBar(this.side);
        this.clearBar(this.footer);
    },
    clearBar: function(view){

        if(view) {
            view.stopListening();
            view.undelegateEvents();
            view.$el.empty();
            delete view.source;
        }
        view = null;
    }
});

module.exports = TheRouter;