require('../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../prototypes/generic.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader!../templates/dashboard.template.hbs');
require("loader");

var TheView = GenericView.extend({
    el: $("#content"),
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {

        $.loader.show();
        this.render();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		this.$el.html(this.template());
		$.loader.hide();

	},
	events:{
	}
});


module.exports= TheView;