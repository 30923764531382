require('../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../prototypes/generic.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader!../templates/login.template.hbs');
require("notify");

var TheView = GenericView.extend({
    el: $("#full-content"),
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
        this.render();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		var self = this;

		this.$el.html(this.template());

	},
	events:{
	    "click #login" : "authenticateUser",
	    "keyup .login-form input": "loginWithEnter"
	},
	authenticateUser: function(){
        this.getPermissionToken()
	},
	getPermissionToken: function(){
	    var self = this
        var username = encodeURIComponent($('#username').val());
        var password = encodeURIComponent($('#password').val());
        $.ajaxDefaultQ({
            url: window.ip + '/open/api/grant/login',
            type: 'POST'
        }).done(function(response, status, xhr){
            console.info(xhr.getAllResponseHeaders())
            console.info(xhr.getResponseHeader("Set-Cookie"))
            console.info(response)
            if(status == "success"){
                 self.authenticateUserWithServer(username, password, response.data.permissionToken);
            }
        })
        .fail(function(response) {
          console.info(response);
        })
	},
	authenticateUserWithServer: function(username, password, token){

        $.ajaxDefaultQ({
            url: window.ip + '/auth/login',
            type: 'POST',
            data: "username=" + username + "&password=" + password +"&token=" + token
        }).done(function(response, status, xhr){
            console.info(response);
            console.info(status);
            console.info(xhr);
            if(status == "success"){
                window.open('./','_self');
            }
        })
        .fail(function(response) {
            $.notify("Λάθος στοιχεία σύνδεσης", { position:"top center", className: "error"});
        });
	},
	loginWithEnter: function(event)
    {
        event.preventDefault();
        if (event.keyCode == 13) {
            if($("#username").val() != "" && $("#password").val() != null)
                this.authenticateUser();
        }
    }
});


module.exports= TheView;