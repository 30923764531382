define('ip_popup', function () {
    require("../../jquery-ui/jquery-ui.min.js");
    var Handlebars = require('handlebars');
    var Results = null;

    var templates = {
        "results": require("text-loader!./templates/results.html")
    };

    var methods = {
        "results": function(element, title, content){
            return results(element, title, content);
        }
    };

    var element = $("<div id='popup_div'></div>");
    var show = function(title, content, templateName, options ) {

        var self = this;
        if ( $("#popup_div") )
            $("#popup_div").remove();

        var template = templates[templateName];
        if (template) {
            element.html(template);
            Results = options.results;
            methods[templateName](element, title, content);
            $("body").append(element);
            bind_events();
        }
    };

    var hide = function() {
        if ( $("#popup_div") )
            $("#popup_div").remove();
    }

    var bind_events = function() {
        var self = this;

        $('.popup-close').off("click").on("click", function() {
            if ( $("#popup_div") )
                $("#popup_div").remove();
        });

        $(".popup-top").off("mousedown mouseup").on("mousedown mouseup", function() {
            $("#popup_div .popup").toggleClass("grabbing");
        });

        $("#popup_div .popup").draggable();

        $('#cancelB').off("click").on("click", function() {
            if ( $("#popup_div") )
                $("#popup_div").remove();
        });
    };

    var results = function (element, title, content) {
        $(element.find(".popup-title label")[0]).text(title);
        $.each(Results, function(index, turn) {
            var turnRow = `<tr><td>${Handlebars.helpers.returnShapeIcon(turn.targetShape)}</td> <td>${Handlebars.helpers.returnShapeIcon(turn.answerShape)}</td></tr>`;
            element.find("#turns").append(turnRow);
        });
    };


    return {
        "show": show,
        "hide": hide
    };
});