require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/activation/activation.template.hbs');
require("notify");

var TheView = GenericView.extend({
    el: $("#full-content"),
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
	    if(options.token == null){
            Backbone.history.navigate("", { trigger : true } );
            return;
        }
        this.activateAccountWith(options.token)
        this.render();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		var self = this;

		this.$el.html(this.template());

	},
	events:{
	    "click #login" : "authenticateUser"
	},
	activateAccountWith: function(token){
        $.ajaxDefaultQ({
            url: window.ip + '/open/api/activate?r='+token,
            type: 'POST'
        }).done(function(response, status, xhr){
            console.info(response)
            $("#activationSuccess").show();
            $("#activationLoading").hide();
        })
        .fail(function(response) {
          console.info(response);
          $("#activationFailed").show();
          $("#activationLoading").hide();
          //$.notify("Τα δεδομένα αποθηκεύτηκαν με επιτυχία", { position:"top center", className: "success"});
        })
	}
});


module.exports= TheView;