require("../../libs/backbone/backbone.ajaxq.adapter.ts");
var Handlebars = require('handlebars');

var TheView = Backbone.View.extend({
	Template: null,
	template: null,
	myDomId: null,
	domain: null,
	options: {},
	initialize: function () {
	},
	//Run method when user want to go one page back
	goBack: function()
	{
		//with this code we go to the previous page
		window.history.go(-1);
	},
	fetch: function(model, successCallback, errorCallback){
        var self = this;
        $.loader.show();
        model.fetch(
        {
           success: function(response){  if(successCallback != null) successCallback.apply(self, [response]);   $.loader.hide(); },
           error: function(response, xhr, status){ if(errorCallback != null) errorCallback.apply(self, [response, xhr, status]); $.loader.hide() }
        } );
    },
    save: function(model, successCallback, errorCallback){
        var self = this;
        //console.log(this.model);
         $.loader.show();
        model.save(null,
        {
           success: function(response){  $.loader.hide(); successCallback.apply(self); },
           error: function(response){   $.loader.hide();errorCallback.apply(self); }
        } );
    }
});

module.exports = TheView;