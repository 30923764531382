require('../../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/patient/examinations/examinations.template.hbs');
require("loader");
require("notify");
var Validation = require("validation");

var TheView = GenericView.extend({
    el: "#tab3",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
	    this.model = options.model;
	    this.patientId = options.patientId;
	    this.isDoctor = options.isDoctor;
        this.render();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		this.$el.html(this.template({
		    model: this.model.toJSON()
		}));
	},
	events:{
	    "click .examinations tr": "openExamination",
	},
	openExamination: function(e){
	    var element = $(e.currentTarget);
	    var type = element.data("type");
	    var examinationId = element.data("id")
	    console.info("open examination "+ element.data("type")+" "+ examinationId+" "+this.patientId);

	    if(this.isDoctor == false)
            Backbone.history.navigate('#Examination/'+type+'/' + examinationId, { trigger : true } );
	    else
            Backbone.history.navigate('#Examination/'+type+'/' + examinationId+'/'+this.patientId, { trigger : true } );
	},
});

module.exports= TheView;
//address: (1) […]
//0: Object { street: "asdasd", number: "21", postalCode: "23", … }
//contactInfo: Object { phone: "", mobile: "", email: "d.amoutzidis@msensis.com" }
//dateOfBirth: 1586256336708
//educationLevel: "Other"
//educationLevelInfo: ""
//familialStatus: "Single"
//familialStatusInfo: ""
//fathersName: "Γεώργιος"
//firstName: "Δημήτριος"
//gender: "Male"
//hobbies: ""
//id: "5e8c59d0b8144d3701d43fdb"
//lastName: "Αμουτζίδης"
//nationality: "Ελληνική"
//occupation: ""
//referredFrom: ""
//technologyAptitudeLevel: "Medium"
//userDeviceInfo: ""