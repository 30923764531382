require('../../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/patient_new/details/details.template.hbs');
var DetailsModel = require('../../../models/patientPersonalInfo.model.ts');
require("loader");
require("notify");
var Validation = require("validation");

var TheView = GenericView.extend({
    el: "#tab-content",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
	    this.id = options.id;
//	    this.patientId = options.patientId

        this.model = new DetailsModel();
        if(this.id != null){
            this.model.url = window.ip + "/api/doctor/patients/" + this.id + "/info/personal"
        }else{
            this.model.url = window.ip + "/api/patient/info/personal"
        }

        this.fetch(this.model, this.onFetchSuccess, this.onFetchFail);
        this.render();
	},
	onFetchSuccess: function(){
        this.render();
	},
	onFetchFail: function(){
         this.render();
	},
    initializeValidation: function(){
        this.validation = new Validation();
        this.validation.addValidations(
        {
            firstName: [],
            lastName: [],
            fathersName: [],
            nationality: [],
            email: [ {pattern: "email"} ],
            mobile: [ {pattern: "phone"} ],
            familialStatus: [],
            gender: []
        });
    },
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		this.$el.html(this.template({
		    model: this.model.toJSON()
		}));

		this.initializeValidation();
	},
	events:{
        "click #savePersonal": "check",
	},
	check: function(){
	    if( this.validation.isAllFieldValidate() )
            this.save(this.buildModel(), this.onSuccessSave, this.onErrorSave)
        else
            $.notify("Παρακαλώ ελέγξτε τα δεδομένα σας", { position:"top center", className: "error"});
    },
    onSuccessSave: function(data, statusText, xhr){
        $.notify("Τα δεδομένα αποθηκεύτηκαν με επιτυχία", { position:"top center", className: "success"});
    },
    onErrorSave: function(model, response){
        $.notify("Τα δεδομένα δεν αποθηκεύτηκαν", { position:"top center", className: "error"});
    },
	buildModel: function(){
	    if(this.model.get("patientId") == null){
	        this.model.set("patientId", this.id);
	        this.model.set("id", null);
	        this.model.set("educationLevel", "Other");
            this.model.set("educationLevelInfo","");
            this.model.set("dateOfBirth", 1586256336708);
            this.model.set("hobbies", "");

            this.model.set("occupation", "");
            this.model.set("referredFrom", "");
            this.model.set("technologyAptitudeLevel", "Medium");
            this.model.set("userDeviceInfo", "");
            this.model.set("familialStatusInfo", "");
        }
        this.model.set("firstName", $("#firstName").val().trim());
        this.model.set("lastName", $("#lastName").val().trim());
        this.model.set("fathersName", $("#fathersName").val().trim());
        this.model.set("nationality", $("#nationality").val().trim());
        this.model.set("occupation", $("#occupation").val().trim());
        this.model.set("referredFrom", $("#referredFrom").val().trim());
        this.model.set("hobbies", $("#hobbies").val().trim());
        this.model.set("userDeviceInfo", $("#userDeviceInfo").val().trim());

        var jsDate = $('#dateOfBirth').val().trim();
        var date = new Date(jsDate);
        this.model.set("dateOfBirth", date.valueOf());

        if($("#gender").val() != undefined)
            this.model.set("gender", $("#gender").val().trim());

        if($("#familialStatus").val() != undefined)
            this.model.set("familialStatus", $("#familialStatus").val().trim());

        if($("#technologyAptitudeLevel").val() != undefined)
            this.model.set("technologyAptitudeLevel", $("#technologyAptitudeLevel").val().trim());

        if($("#educationLevel").val() != undefined)
            this.model.set("educationLevel", $("#educationLevel").val().trim());

        var contactInfo = {
            "mobile": $("#mobile").val().trim(),
            "phone": $("#phone").val().trim(),
            "email": $("#email").val().trim()
        }
        this.model.set("contactInfo", contactInfo);


        var street = $("#street").val().trim();
        var number = $("#number").val().trim();
        var postalCode = $("#postalCode").val().trim();
        var city = $("#city").val().trim();
        var state = $("#state").val().trim();
        var country = $("#country").val().trim();
        if(street != "" && number != "" && postalCode != "" && city != "" && state != "" && country != ""){
            var address = [{
                        "street": $("#street").val().trim(),
                        "number": $("#number").val().trim(),
                        "postalCode": $("#postalCode").val().trim(),
                        "city": $("#city").val().trim(),
                        "state": $("#state").val().trim(),
                        "country": $("#country").val().trim()
                    }]
            this.model.set("address", address);
        }


	    return this.model;
	}
});

module.exports= TheView;
//address: (1) […]
//0: Object { street: "asdasd", number: "21", postalCode: "23", … }
//contactInfo: Object { phone: "", mobile: "", email: "d.amoutzidis@msensis.com" }
//dateOfBirth: 1586256336708
//educationLevel: "Other"
//educationLevelInfo: ""
//familialStatus: "Single"
//familialStatusInfo: ""
//fathersName: "Γεώργιος"
//firstName: "Δημήτριος"
//gender: "Male"
//hobbies: ""
//id: "5e8c59d0b8144d3701d43fdb"
//lastName: "Αμουτζίδης"
//nationality: "Ελληνική"
//occupation: ""
//referredFrom: ""
//technologyAptitudeLevel: "Medium"
//userDeviceInfo: ""