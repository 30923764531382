﻿var Handlebars = require('handlebars');
//var UserPartialTemplate = require('text-loader!../../js/templates/partials/userRow.partial.template.hbs');

//Handlebars.registerPartial('userPartialTemplate', UserPartialTemplate);

function replaceGreekAccents(str) {
    var replacements = {
        'Ά' : 'Α',
        'Ί' : 'Ι',
        'Ύ' : 'Υ',
        'Ή' : 'Η',
        'Ό' : 'Ο',
        'Ώ' : 'Ω',
        'Έ' : 'Ε',
        'Ϊ' : 'Ι',
        'Ϋ' : 'Υ'
    };

    $.each(replacements, function(key, value) {
        str = str.replace(new RegExp(key, 'g'), value);
    });

    return str;
}

Handlebars.registerHelper('if_eq', function(a, b, options) {

    if(a == b) // Or === depending on your needs
        return options.fn(this);
    else
        return options.inverse(this);
});

Handlebars.registerHelper('plusOne', function(a, options) {
    return a+1;
});

Handlebars.registerHelper('plus', function(a, b, options) {
    return a+b;
});

Handlebars.registerHelper('correct', function(a, options) {
    if (a >= 3)
        return "correct";
    else
        return "";
});

Handlebars.registerHelper('wrong', function(a, options) {
    if (a >= 2)
            return "wrong";
        else
            return "";
});

Handlebars.registerHelper('returnShapeIcon', function(shape, options) {
    switch(shape) {
        case "Triangle" : return '<i class="fa fa-play" style="transform:rotate(90deg)"></i>';
        case "Rotate Triangle" : return '<i class="fa fa-play" style="transform:rotate(-90deg)"></i>';
        case "Circle" : return '<i class="fa fa-circle"></i>';
        case "Square" : return '<i class="fa fa-square"></i>';
        default: return "-";
    }
});

Handlebars.registerHelper('ifAllowToUseIt', function(role, acceptedRole, options) {
console.log(role+" == "+acceptedRole);
    if(role == acceptedRole) // Or === depending on your needs
        return options.fn(this);
    else
        return options.inverse(this);
});

Handlebars.registerHelper('ifAllowToUseIt', function(role, acceptedRole, options) {
    if(role == acceptedRole) // Or === depending on your needs
        return options.fn(this);
    else
        return options.inverse(this);
});

Handlebars.registerHelper('ifIsEqual', function(one, two, returnType, options) {
       var re = one == two
       //console.info(one+" == "+two+" "+re)
       if(one == two) // Or === depending on your needs
           return returnType
   });

Handlebars.registerHelper('ifIsNotNull', function(value, options) {
      if(value == null) // Or === depending on your needs
              return options.fn(this);
          else
              return options.inverse(this);
  });

Handlebars.registerHelper('convertToDate', function(timeStamp, options) {
//var DateCreated = new Date(Date.parse(Your Date Here)).format("MM/dd/yyyy");
    var date = new Date(timeStamp);
    var yr      = date.getFullYear();
    var month   = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    var day     = date.getDate()  < 10 ? '0' + date.getDate()  : date.getDate();
    var hours   = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var newDate = yr + '/' + month + '/' + day + ' στις ' + hours + ':' + minutes;
    return newDate
});

Handlebars.registerHelper('birthday', function(timeStamp, options) {
//var DateCreated = new Date(Date.parse(Your Date Here)).format("MM/dd/yyyy");
    var date = new Date(timeStamp);
    var yr      = date.getFullYear();
    var month   = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
    var day     = date.getDate()  < 10 ? '0' + date.getDate()  : date.getDate();
    var newDate = yr + '/' + month + '/' + day;
    return newDate
});



Handlebars.registerHelper('allowItToSpecificLevel', function(userLevel, allowLevelCommaSeparated, options) {
    var allowedLevels = allowLevelCommaSeparated.split(",");
    var canAllowThisLevel = false;
    for(var i = 0; i < allowedLevels.length; i++)
    {
        if(userLevel == allowedLevels[i])
        {
            canAllowThisLevel = true;
            break;
        }
    }

    if(canAllowThisLevel)
        return options.fn(this);
    else
        return options.inverse(this);
});


Handlebars.registerHelper('ifIsNotNull', function(value, options) {
    if(value) // Or === depending on your needs
        return options.fn(this);
    else
        return options.inverse(this);
});

Handlebars.registerHelper('defaultValueInCaseOfNull', function(value, text, options) {
    if(value) // Or === depending on your needs
        return value;
    else
        return text;
});

Handlebars.registerHelper('defaultValueInCaseOfNullWithTranslation', function(key, value, defaultText, options) {
    if(value) // Or === depending on your needs
        return i18n.t(key + "." + value);
    else
        return i18n.t(key + "." + defaultText);
});


Handlebars.registerHelper('getFullNameOrUsername', function(name, lastname, username, options) {
    return new Handlebars.SafeString(
        (name != null && name != "")
        ? (lastname != null && lastname != "")
            ? lastname + " " + name
            : name
        : (lastname != null && lastname != "")
             ? lastname
             : username
    );
});

Handlebars.registerHelper('round', function(value, toFixed, options) {
    return value.toFixed(toFixed);
});

Handlebars.registerHelper('perCent', function(value1, value2 ,options) {

    var total = value1 + value2;
    total = total == 0 ? 1 : total;
    var perCentValue  = value1/total * 100;
    return parseInt(perCentValue +"");
});

Handlebars.registerHelper('print', function(value1,options) {
    console.log(value1);
});

//Sample usage: span {{t "my.key" }}
Handlebars.registerHelper('t', function (i18n_key, options) {
    var result;
    let count;
    if (options.hash.count) {
        count = parseInt(options.hash.count);
    } else if (options.hash.hasOwnProperty('count')) {
        count = 0;
    }
    if (options.hash) {
        options.hash.count = count;
        result = i18n.t(i18n_key, options.hash);
    } else {
        result = i18n.t(i18n_key);
    }

    return result;
});

//replaces accents from greek upper case characters
Handlebars.registerHelper('tu', function (i18n_key, options) {
    var result;
    let count;
    if (options.hash.count) {
        count = parseInt(options.hash.count);
    } else if (options.hash.hasOwnProperty('count')) {
        count = 0;
    }
    if (options.hash) {
        options.hash.count = count;
        result = i18n.t(i18n_key, options.hash);
    } else {
        result = i18n.t(i18n_key);
    }
    return replaceGreekAccents(result.toUpperCase());
});

Handlebars.registerHelper('getTime', function (dateInMillis, options) {
    // format the date
    // add leading zeroes to single-digit hours/minutes
    let date = new Date(dateInMillis);
    let d = [
        '0' + date.getHours(),
        '0' + date.getMinutes()
    ].map(it => it.slice(-2)); // take last 2 digits of every component

    // join the components into date
    return d.join(':');
});

Handlebars.registerHelper('getDateTime', function (dateInMillis, options) {
    // format the date
    // add leading zeroes to single-digit day/month/hours/minutes
    let date = new Date(dateInMillis);
    let d = [
        '0' + (date.getMonth() + 1),
        '0' + date.getDate(),
        '0' + date.getHours(),
        '0' + date.getMinutes()
    ].map(it => it.slice(-2)); // take last 2 digits of every component

    // join the components into date
    return date.getFullYear() + '-' + d.slice(0, 2).join('-') + ' ' + d.slice(2).join(':');
});

Handlebars.registerHelper('examToGreek', function (examination, options) {
    // format the date
    // add leading zeroes to single-digit day/month/hours/minutes
    if(examination == "ColorApp")
        return "Χρωματικό Τεστ";
    else if(examination == "Ishihara")
        return "Χρωματικό Τεστ Ishihara";
    else if(examination == "EDTRS")
        return "Οπτική Οξύτητα";
    else if(examination == "VisualAcuity1M")
            return "Οπτική Οξύτητα 1 Μέτρο";
    else if(examination == "Contrast")
        return "Ευαισθησία Φωτεινών Αντιθέσεων";
    else if(examination == "Glare")
        return "Εξέταση Θάμβους";
    else if(examination == "ReadingRate")
        return "Ταχύτητα Ανάγνωσης";
    else
        return examination;
});

Handlebars.registerHelper('eyeToGreek', function (eye, options) {
    // format the date
    // add leading zeroes to single-digit day/month/hours/minutes
    if(eye == "Right")
        return "Δεξί";
    else
        return "Αριστερό";
});

Handlebars.registerHelper('add_percentage', function(a, b, options) {

    if(a == b) // Or === depending on your needs
        return b;
    else
        return a + "%";
});

Handlebars.registerHelper('showCustomResult', function(a, b, options) {
    var a = a == true ? "Σωστό" : "Λάθος";
    if(b == null)
        return a;
    else
        return a + " το " + b;
});