var Validation = function() {
   this.fields = [];
   this.parser = new ValidationParser();
   this.addValidations = function(validations){
          this.fields = this.parser.parse(validations);
   }

   this.isAllFieldValidate = function(){
        var isAllValid = true
        //console.log(this.fields.length);
        for(var i = 0; i< this.fields.length; i++){
            var field = this.fields[i];
           // console.log(field);
            if(!field.isValid()){
                isAllValid = false;
            }
        }
        return isAllValid;
   }
};

function ValidationParser(){

    this.parse = function(validations){
        var fields = []
        for(var key in validations){
            var value = validations[key];
            fields.push(this.createField(key, value));
        }
        //console.log(fields);
        return fields
    }

    this.createField = function(key, array){
          var field = null;
        if($("#"+key).is("select")){
            field = new Select("#"+key);
        }else{
            field = new Field("#"+key);
        }

         for(var key in array){
            var validation = this.validation(array[key]);
            if(validation != null)
                field.add(validation)
         }

        return field;
    }

    this.validation = function(obj){
        var vld = null;
        if(obj["pattern"] != null){
            switch(obj["pattern"]) {
              case "email":
                    vld = new Email();
                break;
              case "number":
                    vld = new Number();
                break;
              case "phone":
                    vld = new Phone();
                break;
            }
        }
        else if(obj["maxLength"] != null)
              vld = new MaxLength(obj["maxLength"]);
        else if(obj["minLength"] != null)
              vld = new MinLength(obj["minLength"]);
        else if(obj["length"] != null)
              vld = new Length(obj["length"]);
        else if(obj["rangeLength"] != null)
              vld = new RangeLength(obj["rangeLength"]);
        else if(obj["equalTo"] != null)
              vld = new EqualsTo("#"+obj["equalTo"]);

        if(obj["msg"]){
            vld.errorMsg = obj["msg"];
        }
        return vld;
    }

}

function Field(id){
    this.group = $(id).parent();
    this.validators = [];

    this.add = function(validator){
        this.validators.push(validator);
    }

    this.required = this.group.hasClass("required");
    if(this.required)
        this.add(new NotEmpty());

    this.getValue = function(){
        return this.group.find("input").val().trim();
    }

    this.addError = function(msg){
        if(msg != null)
            this.group.find("label.error").html('<i class="fa fa-times-circle-o"></i> '+msg);
        this.group.addClass("has-error");
    }

    this.clearError = function(){
        this.group.removeClass("has-error")
    }

    this.isValid = function(){
        var value = this.getValue();
        //console.log("value "+value);
        if(!this.required && value == "")
            return true;

        for(var i = 0; i< this.validators.length; i++){
            var vld = this.validators[i];
            if(!vld.isValid(value)){
                   this.addError(vld.errorMsg)
                   return false;
            }
            this.clearError();
        }
        return true;
    }
    var self = this;
    $(id).keyup(function() {
      self.isValid(self.getValue());
    });
}

function Select(id){
    this.group = $(id).parent();
    this.validators = [];

    this.add = function(validator){
        this.validators.push(validator);
    }

    this.required = this.group.hasClass("required");
    if(this.required)
        this.add(new SelectValidation());

    this.getValue = function(){
        return this.group.find("select").val();
    }

    this.addError = function(msg){
        if(msg != null)
            this.group.find("label.error").html('<i class="fa fa-times-circle-o"></i> '+msg);
        this.group.addClass("has-error");
    }

    this.clearError = function(){
        this.group.removeClass("has-error")
    }

    this.isValid = function(){
        var value = this.getValue();
        //console.log("value "+value);
        if(!this.required)
            return true;

         for(var i = 0; i< this.validators.length; i++){
            var vld = this.validators[i];
            if(!vld.isValid(value)){
                   this.addError(vld.errorMsg)
                   return false;
            }
            this.clearError();
         }

        return true;
    }
    var self = this;
    $( id ).change(function() {
        self.isValid()
    });
}

function EqualsTo(id){
    this.field = new Field(id);
    this.errorMsg = "Τα πεδία δεν είναι ίδια";
    this.isValid = function(value){
        //console.log("EqualsTo");
        if(this.field.getValue() != value){
           this.field.addError();
            return false;
        }
           //console.log("true");
        return true;
    }
}

function NotEmpty(){
    this.errorMsg = "Το πεδίο είναι κενό";
    this.isValid = function(value){
        //console.log("NotEmpty");
        if(value.length == 0){
           //console.log("false");
            return false;
        }
           //console.log("true");
        return true;
    }
}

function MaxLength(max){
    this.max = max;
    this.errorMsg = "λιγότερους από "+this.max+" χαρακτήρες";
    this.isValid = function(value){
       // console.log("MaxLength");
        if(value.length > this.max){
           //console.log("false");
           return false;
        }
       // console.log("true");
        return true;
    }
}

function Length(length){
    this.length = length;
    this.errorMsg = "ακριβώς "+this.length+" χαρακτήρες";
    this.isValid = function(value){
       // console.log("Length");
        if(value.length == this.length){
         //  console.log("false");
           return false;
        }
        //console.log("true");
        return true;
    }
}

function MinLength(min){
    this.min = min;
    this.errorMsg = "περισσότερους από "+this.min+" χαρακτήρες";
    this.isValid = function(value){
        //console.log("MinLength");
        if(value.length <= this.min){
           //console.log("false");
           return false;
        }
        //console.log("true");
        return true;
    }
}

function RangeLength(array){
    this.min = new MinLength(array[0]);
    this.max = new MaxLength(array[1]);
    this.errorMsg = "από "+array[0]+" μέχρι "+array[1]+" χαρακτήρες";
    this.isValid = function(value){
       // console.log("RangeLength");
        if(min.isValid(value) && max.isValid(value)){
          // console.log("false");
           return false;
        }
       // console.log("true");
        return true;
    }
}




function Email(){
    this.pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.errorMsg = "Μη έγκυρο e-mail";
    this.isValid = function(value){
        //console.log("Email");
        if(!this.pattern.test(value)){
           //console.log("false");
           return false;
        }
        //console.log("true");
        return true;
    }
}


function Phone(){
///^((\+|00)\d{1,3}[- ]?)?\d{10}$/;
    this.pattern = /^((\+|00)\d{2,3})?\d{10}$/;
    this.errorMsg = "Μη έγκυρο νούμερο";
    this.isValid = function(value){
        value = value.replace(/ /g,"");
       // console.log("Phone "+value);
        if(!this.pattern.test(value)){
          // console.log("false");
           return false;
        }
        //console.log("true");
        return true;
    }
}


function Number(){
    this.pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.errorMsg = "Μη έγκυρο e-mail";
    this.isValid = function(value){
        //console.log("Email");
        if(!this.pattern.test(value)){
           //console.log("false");
           return false;
        }
       // console.log("true");
        return true;
    }
}

function SelectValidation(){
    this.errorMsg = "Μη έγκυρη επιλογή";
    this.isValid = function(value){
        //console.log("Email");
        if(value == null || value == "-" || value == ""){
           //console.log("false");
           return false;
        }
       // console.log("true");
        return true;
    }
}




module.exports = Validation;