Backbone = require('backbone');
Handlebars = require('handlebars');
Template = require('text-loader! ../../templates/parts/search.htm');
var HandlebarsHelpers = require('../../../libs/handlebars/handlebars-helpers.ts');
require("loader");


var SearchView = Backbone.View.extend({
	events: {
        "click #search-group .btn": "fetchSearchableData",
        "keyup #search-group .form-control": "detectEnter",
        "click #search-group .clear": "clear"
	},
	tagName: 'aside',
	template: _.template(Template),
	lastSearchable: "",
	initialize: function () {
        this.render()
	},
	on: function(callback, context) {
        this.bind('callback', callback, context);
    },
	detectEnter: function(e){
        if (e.keyCode === 13) {  //checks whether the pressed key is "Enter"
            this.fetchSearchableData();
        }
        else{
            this.showClear();
        }
    },
    showClear: function(){
        var searchableLength = $('#search-group .form-control').val().trim().trim().length;
        var clearDiv = $("#search-group .clear");

        if(searchableLength == 0){
            clearDiv.hide();
            return;
        }
        clearDiv.show();
    },
    clear: function(e){
        $(e.currentTarget).hide();
        $('#search-group .form-control').val("")
        this.callback("");
    },
    fetchSearchableData: function(){
	    var searchable = $('#search-group .form-control').val();
        this.callback(searchable);
    },
    callback: function(searchable){
        if(this.lastSearchable != searchable)
            this.trigger('callback', searchable);
        this.lastSearchable = searchable;
    },
	render: function () {
		var html = this.template();
		this.$el.html(html);
		this.delegateEvents(this.events);
	}

});

// Returning instantiated views can be quite useful for having "state"
module.exports= SearchView;