require("../libs/backbone/backbone.ajaxq.adapter.ts");

var TheRouter = require("routers/main.router.ts");

//window.ip = "https://life4lv-cpanel.msensis.com"
window.ip = ""; //SERVER IP
//window.ip = "http://akefalas.tplinkdns.com";
//window.ip = "http://172.16.0.11:8080";

// mock data, remove them and re-enable the comments above and the comments below (inside `done`)
var loggedIn = false; //false;
var data = null
// END mock data

$.ajaxDefaultQ({
	url: window.ip+'/api/user',
	type: 'GET'
}).done(function(response, status, xhr){
    //console.info(xhr)
    //console.info(response)
	if(status == "success"){
		loggedIn = true;
		data = response.data;
	}
})
.fail(function(response) {
  //console.info(response);
  var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
})
.always(function(){
	var router = new TheRouter({loggedIn: loggedIn, data: data});
	Backbone.history.start();
});
