require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/clinic/clinic.template.hbs');
var Model = require('../../ui/clinics/models/clinic.model.ts');
require("loader");
require("notify");
var Validation = require("validation");

var TheView = GenericView.extend({
    el: "#content",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
	    this.self = this
	    this.loadDetails(options);
	},
	isNew: function(){
	    return this.id == null || this.id == "New";
	},
    loadDetails: function(options){
        this.id = options.id;
        this.admin = options.admin

        this.model = new Model();
        this.model.urlRoot = window.ip+"/api/clinic-admin/clinics";
        this.render();

        if(this.isNew()){
            return;
        }

        this.model.set("id", this.id);
        this.fetch(this.model, this.onSuccess, this.onError);
    },
    onSuccess: function(response, xhr, status){
        //this.fetch(this.medicalModel, this.onMedicalSuccessFetch, this.onMedicalErrorFetch);
        this.render();
    },
    onError: function(response, xhr, status){
    },
    initializeValidation: function(){
        this.validation = new Validation();
        //Pattern firstName(is the id from input): [ {maxLength(validation): 10}]
        this.validation.addValidations(
        {
            name: [],
            street: [],
            number: [],
            postalCode: [],
            city: [],
            state: [],
            country: []

        });

    },
    getTitle: function(){
        if(!this.isNew()){
            console.info('new', this.model);
            if(this.model.get("user") == null)
                return "Κλινική: ";

            return "Κλινική: " + this.model.get("name");
        }

        return "Δημιουργία Κλινικής";
    },
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		this.$el.html(this.template({
		    item: this.model.toJSON(),
		    title: this.getTitle()
		}));

        this.initializeValidation();
	},
	events:{
        "click #save": "check",
        "click #cancel": "cancel",
        "click .examinations tr": "openExamination",
	},
	cancel: function(){
	    this.goBack();
	},
	check: function(){
	    console.info(this.validation.isAllFieldValidate());
        if(this.validation.isAllFieldValidate()){
           this.getAdmin();
        }
	},
	onSuccessSave: function(data, statusText, xhr){
        $.notify("Τα δεδομένα αποθηκεύτηκαν με επιτυχία", { position:"top center", className: "success"});
	    if(this.isNew())
	        this.goBack();
	},
	onErrorSave: function(model, response){
        $.notify("Τα δεδομένα δεν αποθηκεύτηκαν", { position:"top center", className: "error"});
	},
	getAdmin: function(){
	    $.loader.show();
	    var self = this;
        $.ajaxDefaultQ({
            url: window.ip+'/api/clinic-admin',
            type: 'GET'
        }).done(function(response, status, xhr){
            self.buildModel(response.data)
        })
        .fail(function(response) {
          //console.info(response);
        })
        .always(function(){
	        $.loader.hide();
        })
	},
	buildModel: function(admin){
        this.model.set("name", $("#name").val().trim());
        this.model.set("description", $("#description").val().trim());
        this.model.set("admin", admin);
        var address = {
            street: $("#street").val().trim(),
            number :$("#number").val().trim(),
            postalCode: $("#postalCode").val().trim(),
            city: $("#city").val().trim(),
            state: $("#state").val().trim(),
            country: $("#country").val().trim()
        }
        this.model.set("address", address);

        this.save(this.model, this.onSuccessSave, this.onErrorSave);
	    //return this.model;
	}
});

module.exports= TheView;
//address: (1) […]
//0: Object { street: "asdasd", number: "21", postalCode: "23", … }
//contactInfo: Object { phone: "", mobile: "", email: "d.amoutzidis@msensis.com" }
//dateOfBirth: 1586256336708
//educationLevel: "Other"
//educationLevelInfo: ""
//familialStatus: "Single"
//familialStatusInfo: ""
//fathersName: "Γεώργιος"
//firstName: "Δημήτριος"
//gender: "Male"
//hobbies: ""
//id: "5e8c59d0b8144d3701d43fdb"
//lastName: "Αμουτζίδης"
//nationality: "Ελληνική"
//occupation: ""
//referredFrom: ""
//technologyAptitudeLevel: "Medium"
//userDeviceInfo: ""