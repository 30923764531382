require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/account/account.template.hbs');
var Model = require('../../ui/doctors/models/doctor.model.ts');
require("loader");
require("notify");
var Validation = require("validation");

var TheView = GenericView.extend({
    el: "#content",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	selectedClinic: null,
	initialize: function (options) {
	    this.loadDetails(options);
	},
	isNew: function(){
	    return this.id == null || this.id == "New";
	},
    loadDetails: function(options){

        this.model = new Model();
        this.model.urlRoot = window.ip+"/api/doctor";
        //this.render();

        this.fetch(this.model, this.onFetchSuccess, this.onFetchFail);

    },
    onFetchSuccess: function(){

    },
    onFetchFail: function(){

    },
    onClinicsSuccess: function(response){
        this.render();
        $.loader.hide();
    },
    onClinicsError: function(response, xhr, options){
        $.loader.hide();
    },
    findSelectedClinic: function(){
        for (i = 0; i < this.collection.models.length; i++) {
            var element = this.collection.models[i];
             console.log(element.attributes.name +" == "+ this.model.get("clinicName"));
            if(element.attributes.name == this.model.get("clinicName")){
                this.selectedClinic = element.attributes.id;
                return;
            }
        }
    },
    onDoctorSuccess: function(response, xhr, status){
        this.fetchClinics();
    },
    onDoctorError: function(response, xhr, status){
    },
    initializeValidation: function(){
        this.validation = new Validation();
        //Pattern firstName(is the id from input): [ {maxLength(validation): 10}]

        this.validation.addValidations(
        {
            firstName: [],
            lastName: [],
            email: [ {pattern: "email"} ],
            specialty: []
        });

    },
    getTitle: function(){
        if(!this.isNew()){
            console.info('new', this.model);
            if(this.model.get("user") == null)
                return "Γιατρός: ";

            return "Γιατρός: " + this.model.get("user").lastName;
        }

        return "Δημιουργία Γιατρού";
    },
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		var self = this;
		this.findSelectedClinic();

		this.$el.html(this.template({
		    item: this.model.toJSON(),
		    title: this.getTitle(),
		    clinics: this.collection.models,
		    clinicId: this.selectedClinic
		}));

        this.initializeValidation();
	},
	setClinics: function(){
        var selectedClinic = $('#clinics select').val();
        if(this.selectedClinic == selectedClinic){
            this.successAlert();
            return
        }

        this.assign(this.selectedClinic, selectedClinic)
	},
	assign: function(deleteId, assignId){
	    var self = this;
	    $.loader.show();
	    var doctorId = this.model.id
        console.log(doctorId+" "+deleteId+" "+assignId);
        var clinicId = deleteId;
        var path = "/api/clinic-admin/doctors/"+doctorId;
        var method = "DELETE";

        if(deleteId == null){
            clinicId = assignId;
            path = path + "/assign";
            method = "PUT"
        }

        console.log(window.ip + path);
	     $.ajaxDefaultQ({
         	url: window.ip + path+"?clinicId="+clinicId,
         	type: method
         }).done(function(response, status, xhr){

            if(deleteId == null){
                self.selectedClinic = assignId;
                self.successAlert();
                return
            }
            self.selectedClinic = null
            self.assign(null, assignId);
            $.loader.hide();
         })
         .fail(function(response) {
            $.loader.hide();
         })
	},
	events:{
        "click #save": "check",
        "click #cancel": "cancel",
        "click .examinations tr": "openExamination",
	},
	cancel: function(){
	    this.goBack();
	},
	check: function(){
	    console.info(this.validation.isAllFieldValidate());
        var validationOne = this.validation.isAllFieldValidate()
        var validationTwo = this.clinicValidation();

        if(validationOne && validationTwo){
          this.save(this.buildModel(), this.onSuccessSave, this.onErrorSave);
        }
	},
	clinicValidation: function(){
	    var parent = $('#clinics');
	    var value = parent.find("select").val();

	    if(value == null){
	        parent.addClass("has-error");
	        return false;
	    }
	    parent.removeClass("has-error");
	    return true;
	},
	onSuccessSave: function(data, statusText, xhr){
	    //$.notify("Τα δεδομένα αποθηκεύτηκαν με επιτυχία", { position:"top center", className: "success"});
        this.setClinics();
	},
	successAlert: function(){

        $.loader.hide();
	    $.notify("Τα δεδομένα αποθηκεύτηκαν με επιτυχία", { position:"top center", className: "success"});
	},
    goBackIfNeeded: function(){
        if(this.isNew())
            this.goBack();
    },
	onErrorSave: function(model, response){
        $.notify("Τα δεδομένα δεν αποθηκεύτηκαν", { position:"top center", className: "error"});
	},
	buildModel: function(){
        this.model.set("specialty", $("#specialty").val().trim());
        var user = this.model.get("user");
        user.firstName = $("#firstName").val().trim();
        user.lastName = $("#lastName").val().trim();
        user.email = $("#email").val().trim();
        var activated = $("#activated").val();
        if(activated == "0")
            user.activated = false;
        else
            user.activated = true;

        this.model.set("user", user);

	    return this.model;
	}
});

module.exports= TheView;
//address: (1) […]
//0: Object { street: "asdasd", number: "21", postalCode: "23", … }
//contactInfo: Object { phone: "", mobile: "", email: "d.amoutzidis@msensis.com" }
//dateOfBirth: 1586256336708
//educationLevel: "Other"
//educationLevelInfo: ""
//familialStatus: "Single"
//familialStatusInfo: ""
//fathersName: "Γεώργιος"
//firstName: "Δημήτριος"
//gender: "Male"
//hobbies: ""
//id: "5e8c59d0b8144d3701d43fdb"
//lastName: "Αμουτζίδης"
//nationality: "Ελληνική"
//occupation: ""
//referredFrom: ""
//technologyAptitudeLevel: "Medium"
//userDeviceInfo: ""