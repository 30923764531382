require('../../../libs/backbone/backbone.ajaxq.adapter.ts');
var GenericView = require('../../prototypes/generic.single.view.ts');
var Handlebars = require('handlebars');
var Template = require('text-loader! ../../ui/examinations/examinations.template.hbs');
var Model = require('../../models/patientAutoExaminations.model.ts');
//var Collection = require('./models/doctors.paginated.collection.ts');
require("loader");
require("notify");

var TheView = GenericView.extend({
    el: "#content",
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	id: null,
	personalInfoUrl: String,
	medicalInfoUrl: String,
	initialize: function (options) {
//	    this.self = this
        this.model = new Model();
        this.model.url = window.ip + "/api/patient/examinations"

	    this.fetchData();
    },
    fetchData: function(){
        this.fetch(this.model, this.onSuccess, this.onError);
    },
    onSuccess: function(response){
        this.render()
    },
    onError: function(response, xhr, options){
        this.render()
    },
    renderClinicAdmins: function(){

    },
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
        this.$el.html(this.template({
            model: this.model.toJSON()
        }));
	},
	events:{
        "click #doctors-table > tbody > tr[data-id]": "navigateToClinicAdmin"
	},
	navigateToClinicAdmin: function(element){
	    var id = $(element.currentTarget).data("id");
        Backbone.history.navigate('#Doctors/' + id, { trigger : true } );
	}
});

module.exports= TheView;
//address: (1) […]
//0: Object { street: "asdasd", number: "21", postalCode: "23", … }
//contactInfo: Object { phone: "", mobile: "", email: "d.amoutzidis@msensis.com" }
//dateOfBirth: 1586256336708
//educationLevel: "Other"
//educationLevelInfo: ""
//familialStatus: "Single"
//familialStatusInfo: ""
//fathersName: "Γεώργιος"
//firstName: "Δημήτριος"
//gender: "Male"
//hobbies: ""
//id: "5e8c59d0b8144d3701d43fdb"
//lastName: "Αμουτζίδης"
//nationality: "Ελληνική"
//occupation: ""
//referredFrom: ""
//technologyAptitudeLevel: "Medium"
//userDeviceInfo: ""